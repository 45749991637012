// eslint-disabled
import React, {useContext} from "react";
import {
  useLocation,
  // useHistory
} from "react-router-dom";
import { Helmet } from "react-helmet";

import CmsContext from "./CmsContext";
import cmsColors from "./cmsThemes/cmsColors";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PGIPage = () => {
  const cmsContext = useContext(CmsContext);
  const CMS_COLORS = cmsContext.findByType("CMS_COLORS");

  const query = useQuery();

  const onBack = (e) => {
    e.preventDefault();

    try {
      window.location.href = "/";
    } catch (err) {
      // console.log(err);
    }

    try {
      window.close();
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      <Helmet
        style={[
          {
            cssText: `${cmsColors(CMS_COLORS)}`,
          },
        ]}
      />
      <div style={{ overflow: "hidden" }}>
        <div className="d-flex justify-content-start align-items-center pl-2">
          <i className="fa fa-chevron-left pr-2" />
          <button
            onClick={onBack}
            type="button"
            className="btn btn-link text-primary cms-text-primary mx-0 px-0 common-back"
          >
            BACK TO HOME
          </button>
        </div>
        <iframe
          title="Payment Gateway"
          style={{
            width: "100%",
            height: "100vh",
          }}
          src={query.get("url")}
        />
      </div>
    </>
  );
};

export default PGIPage;

import * as req from "api/actions";
import * as c from "./constants";

// new & renewal
export const createBusinessNewTransaction = (payload, callback) =>
  req.create(
    c.CREATE_BUSINESS_NEW_TRANSACTION,
    "/api/temp/taxpayers/business_permits",
    payload,
    callback
  );

  // resubmit renew
  export const updateBusinessTransaction = (id, payload, callback) =>
  req.update(
    c.UPDATE_BUSINESS_RENEW_TRANSACTION_RESUBMIT,
    `/api/temp/taxpayers/business_permits/${id}`,
    payload,
    callback
  );

export const downloadForm = (payload, callback) =>
  req.create(
    c.DOWNLOAD_FORMS,
    "/api/temp/taxpayers/business_permits/download_form",
    payload,
    callback,
    { defaultMessage: false }
  );

  // BPLS Download form E2E
export const downloadFormE2E = (payload, callback) =>
req.create(
  c.DOWNLOAD_FORMS_E2E,
  "/api/v1/bpls/taxpayer/business/permit/download_form",
  payload,
  callback,
  { defaultMessage: false }
);

  // bpls/btax quarterly/delinquent
  export const test = {}

  export const searchBilling = (payload, callback) => 
  req.item(
    c.SEARCH_BILLING,
    // '/api/v1/taxpayers/btax_billing_api_imports',
    '/api/temp/taxpayers/business_tax/search',
    callback,
    payload,
  );
  
  export const create = (payload, callback) => 
  req.create(
    c.CREATE, 
    // '/api/v1/taxpayers/business_taxes/billings_from_api_imports',
    '/api/temp/taxpayers/business_tax/create_billing',
    payload, 
    callback)

  export const clearItem = (data) => req.setItem(c.SEARCH_BILLING, data)

  export const getSelectedModuleTransactionRequirements = (id) =>
  req.list(
    c.GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS,
    `/api/v1/taxpayers/application_statuses/${id}/requirements`
  );

// BPLS E2E NEW
export const createNewE2EBusinessApplication = (payload, callback) => 
  req.create(
    c.CREATE_NEW_E2E_BUSINESS_APPLICATION,
    `/api/v1/bpls/taxpayer/business/permit/application_new`,
    payload,
    callback
  )

// per page validation (E2E NEW)
export const getPerPageValidation = (payload, callback) =>
req.create(
  c.GET_PER_PAGE_VALIDATION,
  `/api/v1/bpls/taxpayer/business/permit/validate_payload`,
  payload,
  callback,
  { defaultMessage: false }
)

// BPLS E2E NEW RESUBMIT
export const createNewE2EBusinessApplicationResubmit = (id, payload, callback) => 
  req.create(
    c.CREATE_NEW_E2E_BUSINESS_APPLICATION_RESUBMIT,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/resubmit_new`,
    payload,
    callback
  )

export const getNecessaryFieldsList = (payload, callback) =>
  req.list(
    c.GET_NECESSARY_FIELD_LIST,
    `/api/v1/bpls/taxpayer/common/neccessary_fields`,
    payload,
    callback
  );

// BPLS E2E RENEW get business
export const getBusinessList = (payload, callback) =>
req.list(
  c.GET_BUSINESS_LIST,
  `/api/v1/bpls/taxpayer/businesses`,
  payload,
  callback
);

export const showBusiness = (id, callback) =>
req.item(
  c.SHOW_BUSINESS,
  `/api/v1/bpls/taxpayer/businesses/${id}`,
  callback
);

// BPLS E2E RENEW
export const createRenewE2EBusinessApplication = (payload, callback) =>
  req.create(
    c.CREATE_RENEW_E2E_BUSINESS_APPLICATION,
    // `/api/v1/taxpayers/businesses/${id}`,
    `/api/v1/bpls/taxpayer/business/permit/application_renewal`,
    payload,
    callback
  );

// BPLS E2E RENEW Resubmit
export const createRenewE2EBusinessApplicationResubmit = (id, payload, callback) =>
  req.create(
    c.CREATE_RENEW_E2E_BUSINESS_APPLICATION_RESUBMIT,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/resubmit_renewal`,
    payload,
    callback
  );

// BPLS E2E CHANGE Submit
export const createChangeE2EBusinessApplication = (payload, callback) =>
  req.create(
    c.CREATE_CHANGE_E2E_BUSINESS_APPLICATION,
    '/api/v1/bpls/taxpayer/business/permit/application_change_request',
    payload,
    callback
  );
// BPLS E2E CHANGE resubmit
  export const createChangeE2EBusinessApplicationResubmit = (id, payload, callback) =>
  req.create(
    c.CREATE_CHANGE_E2E_BUSINESS_APPLICATION_RESUBMIT,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/resubmit_application_change_request`,
    payload,
    callback
  );

// per page validation (E2E Change)
export const getPerPageValidationChange = (payload, callback) =>
req.create(
  c.GET_PER_PAGE_VALIDATION_CHANGE,
  `/api/v1/bpls/taxpayer/business/permit/change_request_validate_payload`,
  payload,
  callback,
  { defaultMessage: false },
)

// business Validation (E2E Change)
export const getBusinessValidation = (payload, callback, errCallback,) =>
req.create(
  c.GET_BUSINESS_VALIDATION_CHANGE,
  "/api/v1/bpls/taxpayer/business/permit/validate_business",
  payload,
  callback,
  { defaultMessage: false },
  errCallback,)

// BPLS Quarterly Inquiry
  export const quarterlyInquiry = (payload, callback) => 
  req.create(
    c.QUARTERLY_INQUIRY,
    '/api/v1/bpls/taxpayer/business/permit/inquire_quarterly_billing',
    payload,
    callback,
    { defaultMessage: false }
  );

// BPLS Quarterly Submit
  export const createQuarterlyE2EBusinessApplication = (payload, callback) =>
  req.create(
    c.CREATE_QUARTERLY_E2E_BUSINESS_APPLICATION,
    `/api/v1/bpls/taxpayer/business/permit/application_quarterly`,
    payload,
    callback
  );

// BPLS Delinquent Inquiry
export const delinquentInquiry = (payload, callback) => 
req.create(
  c.DELINQUENT_INQUIRY,
  '/api/v1/bpls/taxpayer/business/permit/inquire_delinquent_billing',
  payload,
  callback,
  { defaultMessage: false }
);

// BPLS Delinquent Submit
export const createDelinquentE2EBusinessApplication = (payload, callback) =>
req.create(
  c.CREATE_DELINQUENT_E2E_BUSINESS_APPLICATION,
  `/api/v1/bpls/taxpayer/business/permit/application_delinquent`,
  payload,
  callback
);

// BPLS DELINQUENT RESUBMIT
export const resubmitDelinquentE2EBusinessApplication = (id, payload, callback) =>
req.create(
  c.RESUBMIT_DELINQUENT_E2E_BUSINESS_APPLICATION,
  `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/resubmit_delinquent`,
  payload,
  callback
);

// BPLS Retirement Submit
export const createRetirementE2EBusinessApplication = (payload, callback) =>
req.create(
  c.CREATE_RETIREMENT_E2E_BUSINESS_APPLICATION,
  `/api/v1/bpls/taxpayer/business/permit/application_retirement`,
  payload,
  callback
);

// BPLS RETIREMENT RESUBMIT
export const resubmitRetirementE2EBusinessApplication = (id, payload, callback) =>
req.create(
  c.RESUBMIT_RETIREMENT_E2E_BUSINESS_APPLICATION,
  `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/resubmit_application_retirement/`,
  payload,
  callback
);
import React from 'react';
import PropTypes from 'prop-types';
import emptyStateImage from 'assets/img/icn-empty-state.svg';

function EmptyState({ message }) {
  return (
    <div className="d-flex align-items-center justitfy-content-center h-100">
      <div className="text-center mx-auto py-4">
        <img
          src={emptyStateImage}
          className="img-responsive image-wrapper mb-5"
          alt="Empty State"
        />
        <p className="emp-title mb-0">{message}</p>
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  message: 'There are no records found.',
};

EmptyState.propTypes = {
  message: PropTypes.string,
};

export default EmptyState;

import React, { useContext } from "react";
import session from 'assets/img/404-01.svg';
import { Link } from "react-router-dom";
import Modal from "modules/common/components/Modal";
import { ModalContext } from "App";
import LoginContainerModal from "modules/auth/modals/LoginContainerModal";

export default function serviceUnavailable() {

  const setModal = useContext(ModalContext);

  const handleOpenAuthModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <LoginContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  return (
    <Modal.Body className="p-0">
      <div className='container-fluid text-center'>
        <img src={session} alt="" className="icon-logo" />
        <div className="px-3">
          <Link
            to="/"
            onClick={handleOpenAuthModal}
            className="btn btn-primary w-100 mt-3 cms-bg-color-primary cms-outline-primary"
          >
            Back to Login
          </Link>
        </div>
      </div>

    </Modal.Body>
  );
}

const sessionId = () => {
  try {
    return {
      auth_code: localStorage.getItem('token'),
    };
  } catch (error) {
    console.log(error); // eslint-disable-line
    return {};
  }
};

const clear = () => localStorage.clear();

const set = (key, value) => localStorage.setItem(key, value);

const get = key => localStorage.getItem(key);

const getJson = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || {};
  } catch (err) {
    return {};
  }
};

const clearAll = () => localStorage.clear();

const removeItem = key => localStorage.removeItem(key);

export {
  sessionId,
  clear,
  set,
  get,
  getJson,
  clearAll,
  removeItem,
};

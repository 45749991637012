// /* global my */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { get } from "session";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import EmptyState from "modules/common/components/EmptyState";
import Select from "react-select";
import {
  reactSelectCustomStyle,
  // handleOpenPgiUrl,
  scrollToTop,
  payloadToUpperCase,
} from "helper";
import InputMask from "react-input-mask";
import useFormValidator from "useFormValidator";
import { ModalContext } from "App";
import GCashIcon from "assets/img/gcash-white.png";
import PGINote2 from "modules/common/components/PGINote2";
import CmsContext from "CmsContext";
import { VALID_IDS } from "constant";
// import PaymentGatewayIframeModalContent from 'modules/common/components/PaymentGatewayIframeModalContent';
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import * as actions from "../actions";
import * as lookUps from "../../../constants";
import * as c from "../constants";
// import { generatePGI } from "../../../actions";
// import { periodOptions } from "../../RPTAX-ANGELES/constants";

const years = _.range(1970, +moment().format("YYYY") + 1).sort((a, b) => b - a);

const yearOptions = years.map((year) => ({
  label: year,
  value: year,
  disabled: year === moment().year() ? "yes" : "no",
}));

// TODO: refactor later, add some configs to CMS

const BPLSSearchBilling = ({
  searchBilling,
  searching,
  billingData,
  clearItem,
  create,
  // processPGI,
  PGIgenerating,
  creating,
  module,
  // processGetTransactionHistoryList,
  isGCashLogin,
}) => {
  const [showTable, setShowTable] = useState(false);
  const history = useHistory();
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_MASKINGS = cmsContext.findByType("MASKINGS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const disableOption = (index, data) => (data + 1 > index ? "yes" : "");

  const perioOptions = [
    {
      label: "1st Quarter",
      value: 1,
      disabled: disableOption(1, _.get(billingData, "qtr_from")),
    },
    {
      label: "2nd Quarter",
      value: 2,
      disabled: disableOption(2, _.get(billingData, "qtr_from")),
    },
    {
      label: "3rd Quarter",
      value: 3,
      disabled: disableOption(3, _.get(billingData, "qtr_from")),
    },
    {
      label: "4th Quarter",
      value: 4,
      disabled: disableOption(4, _.get(billingData, "qtr_from")),
    },
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    bin: "",
    period: "",
    year: "",
    valid_id_to_present: "",
  });

  const validateForm = useFormValidator(
    formData,
    {
      bin: "required",
      year: "required",
      period: "required",
    },
    {
      bin: {
        required: "Business Identification Number (BIN)",
      },
    }
  );

  useEffect(() => {
    let rules = {};

    if (_.get(CMS_COMPONENTS, "BTAX_BIN_INPUT_FREE_TEXT")) {
      rules = {
        ...rules,
        bin: "required",
      };
    }

    if (!_.get(CMS_COMPONENTS, "BTAX_BIN_INPUT_FREE_TEXT")) {
      rules = {
        ...rules,
        bin: `required|mask:${_.get(CMS_MASKINGS, "BTAX_BIN_REGEX")}~${_.get(
          CMS_MASKINGS,
          "BTAX_BIN"
        )}`,
      };
    }

    // if(!_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_PERIOD_FILTER')){
    // if(_.get(module, 'transaction_type') !== 'DELINQUENT') {
    //   rules = {
    //     ...rules,
    //     period: 'required'
    //   }
    // }
    // }

    // if(!_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_YEAR_FILTER')){
    if (_.get(module, "transaction_type") !== "QUARTERLY") {
      rules = {
        ...rules,
        year: "required",
      };
    }
    // }

    validateForm.onSetObjectRules(rules);
  }, [
    _.get(CMS_COMPONENTS, "BTAX_BIN_INPUT_FREE_TEXT"),
    _.get(CMS_COMPONENTS, "BTAX_WITHOUT_PERIOD_FILTER"),
    _.get(CMS_COMPONENTS, "BTAX_WITHOUT_YEAR_FILTER"),
  ]);

  const validateValidId = useFormValidator(formData, {
    valid_id_to_present: "required",
  });

  useEffect(() => {
    scrollToTop();
  }, [isSubmitted]);

  useEffect(() => {
    validateForm.validate();
    validateValidId.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setFormData({});
      clearItem({});
    };
  }, []);

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelect = (name) => (e) => {
    setFormData({
      ...formData,
      [name]: e.value,
    });

    if (name === "period") {
      const finalPayload = {
        ...payloadToUpperCase({
          ...formData,
        }),
        [name]: e.value,
        // valid_id_to_present: _.get(formData, 'valid_id_to_present'),
        application_status_id: _.get(module, "application_status_id") || "",
      };

      // eslint-disable-next-line no-unused-expressions
      e.value ? setShowTable(() => true) : setShowTable(() => false);

      searchBilling(finalPayload, () => setIsSubmitted(true));
    }
  };

  useEffect(() => {
    onChangeSelect("period");
  }, []);

  const onSearchBilling = (e) => {
    e.preventDefault();
    if (!validateForm.response.is_passed) {
      return false;
    }
    let finalPayload = {
      ...payloadToUpperCase({
        ...formData,
      }),
      // valid_id_to_present: _.get(formData, 'valid_id_to_present'),
      application_status_id: _.get(module, "application_status_id") || "",
    };

    // if(_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_YEAR_FILTER')){
    if (_.get(module, "transaction_type") !== "DELINQUENT") {
      finalPayload = _.omit(finalPayload, ["year"]);
    }
    // }

    // if(_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_PERIOD_FILTER')){
    if (_.get(module, "transaction_type") !== "QUARTERLY") {
      finalPayload = _.omit(finalPayload, ["period"]);
    }
    // }

    searchBilling(finalPayload, () => {
      setIsSubmitted(true);
    });

    return true;
  };

  // const formatPeriod = (data) => {
  //   return data
  //     ? data
  //         .replace("-0", " - 1st quarter")
  //         .replace("-1", " - 1st quarter")
  //         .replace("-2", " - 2nd quarter")
  //         .replace("-3", " - 3rd quarter")
  //         .replace("-4", " - 4th quarter")
  //     : "---";
  // };

  const onBack = (e) => {
    e.preventDefault();
    setFormData({ ...formData, period: "" });
    setShowTable(() => false);
    setIsSubmitted(false);
    clearItem({});
  };

  const onSubmitAndPay = (e) => {
    e.preventDefault();

    let finalPayload = {
      ...payloadToUpperCase({
        ...formData,
        application_status_id: _.get(module, "application_status_id") || "",
      }),
      valid_id_to_present: _.get(formData, "valid_id_to_present"),
    };

    // if(_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_PERIOD_FILTER')){
    if (_.get(module, "transaction_type") !== "QUARTERLY") {
      finalPayload = _.omit(finalPayload, ["period"]);
    }
    // }

    // if(_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_YEAR_FILTER')){
    if (_.get(module, "transaction_type") !== "DELINQUENT") {
      finalPayload = _.omit(finalPayload, ["year"]);
    }
    // }

    create(
      finalPayload,
      (res) => {
        const transaction_id = _.get(res, "res.data.included.1.id");
        setModal({
          isOpen: false,
        });
        setIsSubmitted(false);
        setFormData({});
        clearItem({});
        history.push(`/transaction-history/${transaction_id}`);
      }
      // (response) => {
      //   processGetTransactionHistoryList();
      //   processPGI(
      //     _.get(response, "data.relationships.transaction_history.data.id"),
      //     (url, res) => {

      //       if(window.my && isGCashLogin){
      //         my.postMessage({
      //           url: _.get(res, 'included.gcashMiniProgramLogs.attributes.response.data.actionForm.redirectionUrl')
      //         });
      //       }else{
      //         handleOpenPgiUrl(url);
      //       }

      //       setModal({
      //         isOpen: false,
      //       });

      //       setIsSubmitted(false);
      //       setFormData({});
      //       clearItem({});
      //       history.push("/success-page");
      //     }
      //   );
      // }
    );
  };

  const checkPeriodOptions = (period) => {
    const options = ["1ST", "2ND", "3RD", "4TH"];
    return searching ? (
      <i className="fa fa-spinner fa-spin" />
    ) : (
      `${options[period > 0 ? period - 1 : period]} QUARTER`
    );
  };

  const payLabel = isGCashLogin ? `Pay with GCASH` : `Pay Now`

  const renderTable = (
    <>
      <table className="table tabl-sm table-striped table-sm mb-0 border-bottom">
        <tbody>
          <tr>
            <td>DATE PROCESSED</td>
            <td>:</td>
            <td>
              <b>
                {_.get(billingData, "date_processed")
                  ? moment(`${_.get(billingData, "date_processed")}`).format(
                      "MMM DD, YYYY"
                    )
                  : "---"}
              </b>
            </td>
          </tr>
          <tr>
            <td>EXPIRATION DATE</td>
            <td>:</td>
            <td>
              <b>
                {_.get(billingData, "expiration_date")
                  ? moment(`${_.get(billingData, "expiration_date")}`).format(
                      "MMM DD, YYYY"
                    )
                  : "---"}
              </b>
            </td>
          </tr>
          <tr>
            <td>OWNERSHIP</td>
            <td>:</td>
            <td>
              <b>{_.get(billingData, "ownership") || "---"}</b>
            </td>
          </tr>
          <tr>
            <td>FROM</td>
            <td>:</td>
            <td>
              <b>
                {checkPeriodOptions(_.get(billingData, "qtr_from")) || "---"}
              </b>
            </td>
          </tr>
          <tr>
            <td>TO</td>
            <td>:</td>
            <td>
              <b>{checkPeriodOptions(_.get(billingData, "qtr_to")) || "---"}</b>
            </td>
          </tr>
          <tr>
            <td style={{ width: 150 }}>OWNER NAME</td>
            <td>:</td>
            <td>
              <b>{_.get(billingData, "owner_name") || "---"}</b>
            </td>
          </tr>
          <tr>
            <td style={{ width: 150 }}>ACCOUNT NAME</td>
            <td>:</td>
            <td>
              <b>{_.get(billingData, "account_name") || "---"}</b>
            </td>
          </tr>
          <tr>
            <td style={{ width: 150 }}>ADDRESS</td>
            <td>:</td>
            <td>
              <b>{_.get(billingData, "address") || "---"}</b>
            </td>
          </tr>
          <tr>
            <td style={{ width: 150 }}>ACC NUMBER</td>
            <td>:</td>
            <td>
              <b>{_.get(billingData, "account_number") || "---"}</b>
            </td>
          </tr>
          <tr>
            <td>AMOUNT</td>
            <td>:</td>
            <td>
              <b>
                {
                // TODO: refactor later :D
                // eslint-disable-next-line no-nested-ternary
                searching ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : _.get(billingData, "amount") ? (
                  parseFloat(_.get(billingData, "amount")).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                ) : (
                  "0.00"
                )}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="full-group ">
        <div className="form-row">
          <div className="form-input-group col-12">
            <label htmlFor="last_name" className="full-label mb-0">
              Valid ID to Present Upon Claiming{" "}
              {validateValidId.renderMessage("valid_id_to_present")}
            </label>
            <Select
              className="cms-text-primary"
              styles={reactSelectCustomStyle}
              placeholder="Select"
              value={
                VALID_IDS.find(
                  (item) =>
                    _.get(formData, "valid_id_to_present") ===
                    _.get(item, "value")
                ) || ""
              }
              onChange={onChangeSelect("valid_id_to_present")}
              options={VALID_IDS}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
      </div>
      {!isGCashLogin && (
        <div className="alert alert-primary text-center mt-3" role="alert">
          Note: An additional convenience fee will be charged to use this
          e-payment service, and once a reference number is generated, please
          pay within the day.
        </div>
      )}
      {isGCashLogin && <PGINote2 />}
    </>
  );
  return (
    <>
      {!isSubmitted && _.isEmpty(billingData) && (
        <form onSubmit={onSearchBilling}>
          <div className="form-wrapper mb-3 pb-3  animated fadeIn">
            <div className="full-group ">
              <div className="form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Business Identification Number (BIN){" "}
                    {validateForm.renderMessage("bin")}
                  </label>
                  {_.get(CMS_COMPONENTS, "BTAX_BIN_INPUT_FREE_TEXT") && (
                    <input
                      placeholder={_.get(CMS_MASKINGS, "BTAX_BIN")}
                      className="full-input text-uppercase"
                      autoComplete="none"
                      id="bin"
                      name="bin"
                      onChange={onChangeInput}
                      value={_.get(formData, "bin") || ""}
                    />
                  )}
                  {!_.get(CMS_COMPONENTS, "BTAX_BIN_INPUT_FREE_TEXT") && (
                    <InputMask
                      placeholder={_.get(CMS_MASKINGS, "BTAX_BIN")}
                      className="full-input text-uppercase"
                      maskChar=" "
                      mask={_.get(CMS_MASKINGS, "BTAX_BIN")}
                      autoComplete="none"
                      id="bin"
                      name="bin"
                      onChange={onChangeInput}
                      value={_.get(formData, "bin") || ""}
                    />
                  )}
                </div>
                {/* {!_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_YEAR_FILTER') && ( */}
                {_.get(module, "transaction_type") === "DELINQUENT" ? (
                  <div className="form-input-group col-12">
                    <label htmlFor="last_name" className="full-label mb-0">
                      Year {validateForm.renderMessage("year")}
                    </label>
                    <Select
                      className="cms-text-primary"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isOptionDisabled={(option) => option.disabled === "yes"}
                      options={yearOptions}
                      styles={reactSelectCustomStyle}
                      onChange={onChangeSelect("year")}
                      value={
                        yearOptions.find(
                          (item) =>
                            _.get(item, "value") === _.get(formData, "year")
                        ) || ""
                      }
                    />
                  </div>
                ) : (
                  ""
                )
                // )
                }
              </div>
            </div>
            <div className="full-group pt-3">
              <button
                disabled={searching || !validateForm.response.is_passed}
                type="submit"
                className="btn btn-primary btn-lg w-100 cms-bg-color-primary cms-outline-primary"
              >
                {searching ? <i className="fa fa-spinner fa-spin" /> : `Submit`}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmitted && _.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <EmptyState message="No Record Found" />
          <div className="full-group">
            <button
              onClick={onBack}
              type="button"
              className="btn btn-primary btn-lg w-100 cms-bg-color-primary"
            >
              Back
            </button>
          </div>
        </>
      )}
      {isSubmitted && !_.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <div className="border rounded p-2 my-2 w-100 animated fadeIn alert-secondary animated fadeIn">
            <div className="row">
              <div className="col-12 text-center cms-text-primary">
                Business Identification Number (BIN)
              </div>
              <div className="col-12 text-center cms-text-primary">
                <b>{_.get(billingData, "bin") || "---"}</b>
              </div>
            </div>
          </div>

          {_.get(module, "transaction_type") === "QUARTERLY" ? (
            <div className="form-input-group col-12 px-0">
              <label htmlFor="last_name" className="full-label mb-0">
                Select Period
                {/* {validateForm.renderMessage("period")} */}
              </label>
              {/* {!_.get(CMS_COMPONENTS, 'BTAX_BIN_INPUT_FREE_TEXT') && */}
              {/* } */}
              <Select
                className="cms-text-primary"
                components={{
                  IndicatorSeparator: () => null,
                }}
                isOptionDisabled={(option) => option.disabled === "yes"}
                options={perioOptions}
                styles={reactSelectCustomStyle}
                onChange={onChangeSelect("period")}
                value={
                  perioOptions.find(
                    (item) => _.get(item, "value") === _.get(formData, "period")
                  ) || ""
                }
              />
            </div>
          ) : (
            ""
          )
          // )
          }
          {// !_.get(CMS_COMPONENTS, 'BTAX_WITHOUT_PERIOD_FILTER') &&
          _.get(module, "transaction_type") === "QUARTERLY" && (
            <div className="alert alert-primary mt-2 w-100" role="alert">
              <b>Note:</b> Selecting 4th quarter means you are paying for the
              whole year.
            </div>
          )}

          {// render table based on set conditions for Quarterly so it won't overlap with Delinquent
          _.get(module, "transaction_type") === "QUARTERLY" &&
            showTable === true &&
              _.get(module, "transaction_type") === "QUARTERLY" &&
              renderTable}
          {_.get(module, "transaction_type") === "DELINQUENT" && renderTable}
          <hr />
          <div className="row">
            <div className="col-12 col-md-6 mb-1 mb-md-0 pr-md-1">
              <button
                disabled={PGIgenerating || creating}
                onClick={onBack}
                type="button"
                className="btn btn-primary cms-bg-color-primary btn-lg w-100"
              >
                Back
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button
                onClick={onSubmitAndPay}
                disabled={
                  PGIgenerating ||
                  creating ||
                  !validateValidId.response.is_passed
                }
                type="submit"
                className={`btn btn-success btn-lg w-100 d-flex align-items-center justify-content-center ${
                  isGCashLogin ? "gcash-button" : ""
                }`}
              >
                {isGCashLogin && (
                  <img src={GCashIcon} alt="GCash" width="22" height="22" />
                )}
                {PGIgenerating || creating ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <p className="flex-grow-1 d-flex align-items-center justify-content-center m-0">
                    {payLabel} 
                    {/* PROCEED */}
                  </p>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

BPLSSearchBilling.propTypes = {
  // processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  searchBilling: PropTypes.instanceOf(Function).isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  searching: PropTypes.bool.isRequired,
  billingData: PropTypes.instanceOf(Object).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  // processPGI: PropTypes.instanceOf(Function).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  isGCashLogin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  searching: api.loading.indexOf(c.SEARCH_BILLING) > -1,
  billingData: _.get(api, `${c.SEARCH_BILLING}.item`) || {},
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
  isGCashLogin: !!_.get(
    JSON.parse(get("profile")),
    "attributes.is_gcash_login"
  ),
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    // processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList,
  }),
]);

export default enhance(BPLSSearchBilling);

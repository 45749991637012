// CMS styles here
import _ from 'lodash'
// ${_.get(getColors, 'primary_color')}
const cmsColors = getColors => 
`
    .cms-text-primary {
      color: ${_.get(getColors, 'primary_color')} !important;
    }
    .cms-bg-color-primary-ligt {
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
    }
    .cms-text-seconday {
      color: ${_.get(getColors, 'secondary_color')} !important;
    }
    .cms-text-warning {
      color: ${_.get(getColors, 'warning_color')} !important;
    }
    .cms-text-danger {
      color: ${_.get(getColors, 'danger_color')} !important;
    }
    .cms-text-muted {
      color: ${_.get(getColors, 'muted_color')} !important;
    }
    .cms-text-muted-secondary {
      color: ${_.get(getColors, 'muted_color_secondary')} !important;
    }
    .cms-text-glow {
      color: ${_.get(getColors, 'glow_color')} !important;
    }
    .cms-text-muted-dark {
      color: #9FA2A7 !important;
    }
    .cms-bg-color-white {
      background-color: #fff !important;
    }


    .cms-bg-color-primary {
      background-color: ${_.get(getColors, 'primary_color')} !important;
    }
    .cms-bg-color-secondary {
      background-color: ${_.get(getColors, 'secondary_color')} !important;
    }
    .cms-bg-color-light {
      background-color: ${_.get(getColors, 'light_color')} !important;
    }
    .cms-bg-color-warning {
      background-color: ${_.get(getColors, 'warning_color')} !important;
    }
    .cms-bg-color-danger {
      background-color: ${_.get(getColors, 'danger_color')} !important;
    }
    .cms-bg-color-muted {
      background-color: ${_.get(getColors, 'muted_color')} !important;
    }


    .cms-outline-glow {
      border-color: ${_.get(getColors, 'glow_color')} !important;
    }
    .cms-outline-primary {
      border-color: ${_.get(getColors, 'primary_color')} !important;
    }
    .cms-outline-primary-light {
      border-color: ${_.get(getColors, 'primary_color_light')} !important;
    }
    .cms-outline-bottom-primary {
      border-bottom: ${_.get(getColors, 'primary_color')} !important;
    }
    .cms-outline-bottom-primary.active {
      border-bottom: 5px solid ${_.get(getColors, 'primary_color')} !important;
    }
    .cms-outline-secondary {
      border-color: ${_.get(getColors, 'secondary_color')} !important;
    }
    .cms-outline-warning {
      border-color: ${_.get(getColors, 'warning_color')} !important;
    }
    .cms-outline-danger {
      border-color: ${_.get(getColors, 'danger_color')} !important;
    }
    .cms-outline-muted {
      border-color: ${_.get(getColors, 'muted_color')} !important;
    }

    button {
      text-decoration: none !important;
    }

    .button-proceed {
      background-color: ${_.get(getColors, 'primary_color')} !important;
      color: #fff;
      text-decoration: none;
      border-color: ${_.get(getColors, 'primary_color')} !important;
    }

    .button-proceed, button.cms-bg-color-primary {
      background-color: ${_.get(getColors, 'primary_color')} !important;
      color: #fff !important;
      text-decoration: none !important;
      border-color: ${_.get(getColors, 'primary_color')} !important;
    }

    .button-proceed:disabled, button.cms-bg-color-primary:disabled, button:disabled {
      background-color: #e3e4e5 !important;
      color: #716f6f !important;
      text-decoration: none !important;
      border-color: #66716a !important;
      cursor: not-allowed !important;
      pointer-events: all !important;
    }

    input {
      color: #717171 !important;
    }

    [class$="singleValue"], .css-18ezgd5, [class$="SingleValue"] {
      color: #717171 !important;
    }

    .css-bd8usr-option {
      color: #717171 !important;
      background-color: transparent !important;
    }

    .css-x2ql29-option {
      color: #717171 !important;
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
    }

    .css-cvw0e7-option {
      color: #fff !important;
      background-color: ${_.get(getColors, 'primary_color')} !important;
    }

    label {
      color: #323843 !important;
    }

    .button-edit-icon {
      background-color: ${_.get(getColors, 'primary_color')} !important;
    }

    @media (max-width: 991.98px){
      .profile-content-inside, .gmp-profile-content-inside {
          display: flex;
          width: 100%;
          margin: 0 auto;
          justify-content: center;
          align-items: flex-end;
          background: ${_.get(getColors, 'primary_color')};
          padding-bottom: 70px !important;
          background: linear-gradient(
      180deg
      , ${_.get(getColors, 'primary_color')} 0%, ${_.get(getColors, 'primary_color_light')} 50%, ${_.get(getColors, 'primary_color_light')} 25%);
      }
    }

    .profile-links--item.active, .complete-profile-info .react-tabs__tab--selected {
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
      color: ${_.get(getColors, 'primary_color')} !important;
    }

    .complete-profile-info .react-tabs__tab {
      color: #717171;
    }

    .profile-tabs.active   {
      background-color: ${_.get(getColors, 'primary_color')} !important;
      color: #fff !important;
    }

    .profile-tabs-mobile .profile-tabs {
      background-color: ${_.get(getColors, 'primary_color_light')};
      color: #717171;
    }

    .profile-title-mobile {
      color: ${_.get(getColors, 'primary_color')} !important;
    }

    .profile-links--item {
      background-color: #fff;
      color: #717171;
    }

    .profile-links--item.active {
      background-color: ${_.get(getColors, 'primary_color')} !important;
      color: #fff !important;
    }

    .mobile-profile-info .react-tabs__tab--selected {
      border-bottom: 1px solid ${_.get(getColors, 'primary_color')} !important;
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
      border-bottom-width: 2px !important;
    }

    .mobile-profile-info .react-tabs__tab--selected div.profile-under {
      border-bottom: none !important;
      margin-left: 30px !important;
    }

    .edit-button-title {
      color: #717171 !important;
    }

    .form-search, .search-input {
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
    }

    .transaction-title h2 {
      color: ${_.get(getColors, 'primary_color')} !important;
    }

    .rbc-event.rbc-selected {
      background-color: ${_.get(getColors, 'primary_color')} !important;
      color: ${_.get(getColors, 'secondary_color')} !important;
    }

    .gcash-button {
      background-color: #007CFA !important;
      border-color: #007CFA !important;
    }

    .transaction-history-container {
      border: 1px solid ${_.get(getColors, 'primary_color')} !important;
      padding: 5px;
      border-radius: 10px;
      padding-top: 30px;
      margin-top: 30px !important;
      z-index: 1;
      min-height: 1173px;
    }

    .transaction-history-title {
      position: absolute;
      top: -16px;
      background: #fff;
      width: max-content;
      margin-left: 16px;
      z-index: 2;
    }

    .services-items:hover {
      background: ${_.get(getColors, 'primary_color_light')} !important;
      text-decoration: none;
      cursor: pointer;
    }

    .e-wallet-title {
      font-weight: medium;
      font-size: 15px;
      color: #9FA2A7 !important;
    }

    .e-wallet-item > a {
      color: #717171 !important;
    }

    .e-wallet-item > a.isCurrentPage {
      color: ${_.get(getColors, 'glow_color')} !important;
      
    }

    .e-wallet-list__inner.isCurrentPage {
      background-color: ${_.get(getColors, 'primary_color_light')} !important;
    }

    @media (max-width: 576px){
      .e-wallet-list__inner.isCurrentPage {
        border-bottom: 2px solid ${_.get(getColors, 'glow_color')};
        background-color: transparent !important;
      }
    }

    .cashin-note {
      color: #717171 !important;
      font-size: 12px;
    }
    

    .fund > div.box > div button {
      font-size: 16px;
      font-weight: 600;
      border: 1px solid ${_.get(getColors, 'glow_color')};
      border-radius: 5px;
      width: 100%;
      color: ${_.get(getColors, 'glow_color')} !important;
    }

    
  `

  export default cmsColors;
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { get } from "lodash"

import CmsContext from "../../../CmsContext";
import cmsColors from "../../../cmsThemes/cmsColors";

const Loader = () => {
  const cmsContext = useContext(CmsContext);
  const CMS_COLORS = cmsContext.findByType("COLORS");
  const CMS_LOGO = cmsContext.findByType('LOGOS');

  const cmsLogo = get(CMS_LOGO, 'LOGO.cdnUrl');

  return (
    <>
      <Helmet
        style={[
          {
            cssText: ` 
              ${cmsColors(CMS_COLORS)}

              .loading-circle::after {
    
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                border: 0.2rem solid ${get(CMS_COLORS, 'primary_color')};
                border-top-color: white;
                border-radius: 50%;
                width: 70px;
                height: 70px;
                animation: spin 0.5s infinite linear;
              }
            `
          },
        ]}
      />
      <div className="full-loader-wrapper cms-bg-color-primary">
        <div className="inner-loader-wrapper rounded-circle">
          <div className="loading-circle">
            <img className="icon-logo-loader" src={cmsLogo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;

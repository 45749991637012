import { ajax as rxAjax } from 'rxjs/ajax';

const sessionGet = key => localStorage.getItem(key);

const jsonToQueryString = json => `?${Object.keys(json).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(json[key]) || ''}`).join('&')}`;

const baseURL = process.env.REACT_APP_WS;

const extraHeader = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/json',
};

const get = (url, params = {}) => {
  const token = sessionGet('token');

  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    } : {
      ...extraHeader,
    };

  return rxAjax({
    url: `${baseURL}${url}${jsonToQueryString(params)}`,
    method: 'GET',
    responseType: 'json',
    headers,
  });
};

const post = (url, params = {}) => {
  const token = sessionGet('token');
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    } : {
      ...extraHeader,
    };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: 'POST',
    responseType: 'json',
    headers,
    body: JSON.stringify({
      ...params,
    }),
  });
};

const put = (url, params = {}) => {
  const token = sessionGet('token');
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    } : {
      ...extraHeader,
    };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: 'PUT',
    responseType: 'json',
    headers,
    body: JSON.stringify({
      ...params,
    }),
  });
};

const remove = (url, payload = {}) => {
  const token = sessionGet('token');
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    } : {
      ...extraHeader,
    };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: 'DELETE',
    responseType: 'json',
    headers,
    body: JSON.stringify({
      ...payload,
    }),
  });
};

const onErr = (constant, errCallback) => error => new Promise((resolve) => {
  if (errCallback) errCallback();
  resolve({
    type: 'ON_ERROR',
    error,
    key: constant,
  });
});

const postFormData = (url, formData) => {
  const token = sessionGet('token');
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
    } : {};

  return rxAjax({
    url: `${baseURL}${url}`,
    method: 'POST',
    responseType: 'json',
    headers,
    body: formData,
  });
};

const download = async (key, url, fileName, callback) => {
  const token = sessionGet('token');
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    } : {
      ...extraHeader,
    };
  return new Promise(async (resolve, reject) => {
    try {
      const raw = await fetch(`${baseURL}${url}`, {
        method: 'GET',
        headers,
      });
      const blob = await raw.blob();
      blob.fileName = fileName || 'download';
      const output_url = await URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = output_url;
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      resolve({
        key, url, fileName, callback,
      });
    } catch (err) {
      reject(key, url, fileName, callback);
    }
  });
};

export {
  post,
  put,
  get,
  remove,
  postFormData,
  download,
  onErr,
};

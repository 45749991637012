import * as req from 'api/actions';

export const setModal = (data = {}) => ({
  type: 'SET_MODAL',
  data,
});

export const startLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.concat([z]),
});

export const endLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.filter(y => y !== z),
});

// barangays
export const getBarangayList = () => req.list(
  'GET_BARANGAY_LIST',
  '/api/v1/taxpayers/barangays',
);

export const getCms = () => req.list(
  'CMS',
  '/api/v1/taxpayers/cms',
);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { render } from 'react-dom';

const ConfirmAlert = (
  label,
  onYes,
  onNo,
  options = { yesLabel: 'Yes', noLabel: 'No' },
) => {
  const rootElement = document.getElementById('my-confirm-modal');

  const handleYes = () => {
    if (onYes) {
      onYes();
    }
    render(<div id="my-confirm-modal" />, rootElement);
  };

  const handleNo = () => {
    if (onNo) {
      onNo();
    }
    render(<div id="my-confirm-modal" />, rootElement);
  };

  const handleOnClose = e => {
    e.preventDefault()

    render(<div id="app" />, rootElement);
  }

  render(
    <div>
      <div style={{ position: 'absolute' }}>
        <div className="modal show">
          <div className="modal-dialog" style={{ pointerEvents: 'initial' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirmation</h4>
                <button type="button" className="close" onClick={handleOnClose}>
                  <span>&times;</span>
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <div className="modal-body">
                <label>{label}</label>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary cms-bg-color-primary cms-outline-primary"
                  onClick={handleYes}
                >
                  {options.yesLabel}
                </button>{' '}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleNo}
                >
                  {options.noLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal-backdrop fade show"
          style={{ zIndex: 1050, pointerEvents: 'initial' }}
          onClick={handleNo}
        />
      </div>
    </div>,
    rootElement,
  );
};

export default ConfirmAlert;

import React from 'react';


const PGINote2 = () => {
  return (
    <div className="cms-bg-color-primary-ligt p-3" role="alert">
      <p className="cms-text-primary">
        Note: An additional convenience fee will be charged to use this e-payment service, and once a reference number is generated, please pay within the day.
      </p>
    </div>
  )
}

export default PGINote2
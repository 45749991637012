import React from "react";
import PropTypes from "prop-types";

function OnProcessList({ data }) {
  return (
    <div className='mt-3 t-border'>
      <div className='col-md-12 d-flex text-center justify-content-center mt-3 '>
        <div className='alert alert-danger w-100' role='alert'>
          On Process Properties - You have pending transaction with these
          properties.
        </div>
      </div>
      <table className='ptable'>
        <thead>
          <tr>
            <th width='450'>PIN</th>
            <th width='450'>Brgy.</th>
            <th width='450'>Survey No.</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr>
              <td>{d.new_pin}</td>
              <td>{d.barangay}</td>
              <td>{d.survey_no}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

OnProcessList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default OnProcessList;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import EmptyState from "modules/common/components/EmptyState";
import Select from "react-select";
import { reactSelectCustomStyle } from "helper";
import InputMask from "react-input-mask";
import useFormValidator from "useFormValidator";

import { getTransactionHistoryList } from "modules/transaction-history/actions";
import MiniLoader from "modules/common/components/MiniLoader";
import * as actions from "../actions";
import * as lookUps from "../../../constants";
import * as c from "../constants";
import { generatePGI } from "../../../actions";
import PropertyList from "./components/PropertyList";

const years = _.range(+moment().format("YYYY"),  +moment().format("YYYY") + 2).sort((a, b) => b - a);

const yearOptions = years.map((year) => ({
  label: year,
  value: year,
}));

const RPTSearchBilling = ({
  searchBilling,
  searching,
  billingData,
  clearItem,
  module,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    owner_id: "",
    year: "",
    valid_id_to_present: "",
  });

  const validateForm = useFormValidator(
    formData,
    {
      owner_id: "required",
      year: "required",
    },
    {
      tdn: {
        required: "Tax Declaration Number (TDN)",
      },
      pin: {
        required: "Property Identification Number (PIN)",
      },
    }
  );

  const validateValidId = useFormValidator(formData, {
    valid_id_to_present: "required",
  });

  useEffect(() => {
    validateForm.validate();
    validateValidId.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setFormData({});
      clearItem({});
    };
  }, []);

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelect = (name) => (e) => {
    setFormData({
      ...formData,
      [name]: e.value,
    });
  };

  const onSearchBilling = (e) => {
    e.preventDefault();
    if (!validateForm.response.is_passed) {
      return false;
    }
    setIsSubmitted(true);
    searchBilling(formData, () => {
      setIsSubmitted(true);
    });

    return true;
  };

  const onBack = (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    clearItem({});
  };

  return (
    <>
      {!isSubmitted && _.isEmpty(billingData) && (
        <form onSubmit={onSearchBilling}>
          <div className='form-wrapper mb-3 pb-3  animated fadeIn'>
            <div className='full-group '>
              <div className='form-row'>
                <div className='form-input-group col-12'>
                  <label htmlFor='last_name' className='full-label mb-0'>
                    Owner ID {validateForm.renderMessage("owner_id")}
                  </label>
                  <InputMask
                    placeholder='_________'
                    className='full-input '
                    maskChar=' '
                    mask='*********'
                    autoComplete='none'
                    id='owner_id'
                    name='owner_id'
                    onChange={onChangeInput}
                    value={_.get(formData, "owner_id") || ""}
                  />
                </div>
                <div className='form-input-group col-12'>
                  <label htmlFor='last_name' className='full-label mb-0'>
                    Year {validateForm.renderMessage("year")}
                  </label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    required
                    options={yearOptions}
                    styles={reactSelectCustomStyle}
                    onChange={onChangeSelect("year")}
                    value={
                      yearOptions.find(
                        (item) =>
                          _.get(item, "value") === _.get(formData, "year")
                      ) || ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className='full-group pt-3'>
              <button
                disabled={searching || !validateForm.response.is_passed}
                type='submit'
                className='btn btn-primary btn-lg w-100'>
                {searching ? <i className='fa fa-spinner fa-spin' /> : `Submit`}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmitted && searching && <MiniLoader />}
      {isSubmitted && !_.isEmpty(billingData) && (
        <>
          <PropertyList
            module={module}
            year={_.get(formData, "year")}
            total={_.get(billingData, "total")}
            properties={_.get(billingData, "properties")}
            advance={_.get(billingData, "advance")}
            delinquent={_.get(billingData, "deliquent")}
            pending={_.get(billingData, "pending")}
            owner_id={_.get(formData, "owner_id")}
          />
          <div className='full-group'>
            <button
              onClick={onBack}
              type='button'
              className='btn btn-primary btn-sm w-100'>
              Back to Search Owner ID
            </button>
          </div>
        </>
      )}
      {isSubmitted &&
        !searching &&
        _.isEmpty(_.omit(billingData, ["included"])) && (
          <>
            <EmptyState message='No Record Found' />
            <div className='full-group'>
              <button
                onClick={onBack}
                type='button'
                className='btn btn-primary btn-lg w-100'>
                Back
              </button>
            </div>
          </>
        )}
    </>
  );
};

RPTSearchBilling.propTypes = {
  searchBilling: PropTypes.instanceOf(Function).isRequired,
  searching: PropTypes.bool.isRequired,
  billingData: PropTypes.instanceOf(Object).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  searching: api.loading.indexOf(c.SEARCH_BILLING) > -1,
  billingData: _.get(api, `${c.SEARCH_BILLING}.item`) || {},
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList,
  }),
]);

export default enhance(RPTSearchBilling);

import * as req from "api/actions";
import * as c from "./constants";

export const autoAuth = (payload, callback) =>
  req.create(c.AUTO_AUTH, "/api/v1/mobile_auth", payload, callback, {
    defaultMessage: false,
  });

  export const gmpAutoAuth = (payload, callback) =>
  req.create(c.AUTO_AUTH, "/api/v1/taxpayer/gmp_sso_auth", payload, callback, {
    defaultMessage: false,
  });

  export const superAppAutoAuth = (payload, callback) =>
  req.create(c.AUTO_AUTH, "/api/v1/taxpayer/super_app_sso_auth", payload, callback, {
    defaultMessage: false,
  });

export const getModuleTypeListForLanding = (params, callback) =>
  req.list(
    c.GET_MODULE_TYPE_LIST,
    "/api/v1/taxpayers/module_systems",
    params,
    callback
  );

export const getArticles = (params, callback) =>
  req.list(c.GET_ARTICLES, "/api/v1/taxpayers/news_articles", params, callback);

export const getFeaturedArticles = (params, callback) =>
  req.list(
    c.FEATURED_ARTICLES,
    "/api/v1/taxpayers/news_articles",
    params,
    callback
  );

export const getFeaturedVideos = (params, callback) =>
  req.list(
    c.FEATURED_VIDEOS,
    "/api/v1/taxpayers/news_articles",
    params,
    callback
  );

export const getArticleInfo = (id, payload, callback) =>
  req.item(
    c.GET_ARTICLE_INFO,
    `/api/v1/taxpayers/news_articles/${id}`,
    payload,
    callback
  );

export const getServices = (payload, callback) =>
  req.list(
    c.GET_SERVICES,
    "/api/v1/site_e_services_menu",
    payload,
    ({ res }) => {
      const services = JSON.stringify(res.data || []);
      localStorage.setItem("services", services);
      if (callback) callback();
    }
  );

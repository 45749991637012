import React, { useContext } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import CmsContext from "CmsContext";

const MiniLoader = ({ error }) => {
  const cmsContext = useContext(CmsContext);
  const CMS_COLORS = cmsContext.findByType("COLORS");
  if (error) {
    return <div>Error!</div>;
  }
  return (
    <>
      <Helmet
        style={[
          {
            cssText: ` 

              .loading-circle::after {
    
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                border: 0.2rem solid ${get(CMS_COLORS, 'primary_color')};
                border-top-color: white;
                border-radius: 50%;
                width: 70px;
                height: 70px;
                animation: spin 0.5s infinite linear;
              }
            `
          },
        ]}
      />
      <div className="mini-loader-wrapper">
        <div className="loading-circle" />
      </div>
    </>
  );
};

MiniLoader.propTypes = {
  error: PropTypes.string,
};

MiniLoader.defaultProps = {
  error: '',
};

export default MiniLoader;

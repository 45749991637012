import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const MetaTagDecorator = ({
  title,
  siteName,
  description,
  imageUrl,
  videoUrl,
  siteUrl,
  twitterCard,
  twitterImageAlt,
}) => (
  <Helmet>
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:video" content={videoUrl} />
    <meta
      property="og:url"
      content={siteUrl + window.location.pathname + window.location.search}
    />
    <meta name="twitter:card" content={twitterCard} />
    <meta property="twitter:image:alt" content={twitterImageAlt} />
  </Helmet>
);

MetaTagDecorator.defaultProps = {
  title: "",
  siteName: "",
  description: "",
  imageUrl: "",
  videoUrl: "",
  siteUrl: "",
  twitterCard: "",
  twitterImageAlt: "",
};

MetaTagDecorator.propTypes = {
  title: PropTypes.string,
  siteName: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  siteUrl: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterImageAlt: PropTypes.string,
};

export default MetaTagDecorator;

export const MODULE = "RPT";

export const SEARCH_BILLING = `${MODULE}/search_billing`;
export const CREATE = `${MODULE}/create_rpt`;
export const CALCULATE = `${MODULE}/calculate`;

export const periodOptions = [
  {
    label: "1st",
    value: 1,
  },
  {
    label: "2nd",
    value: 2,
  },
  {
    label: "3rd",
    value: 3,
  },
  {
    label: "4th",
    value: 4,
  },
];

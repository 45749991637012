import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { random } from 'lodash';
import FilterBadWords from 'bad-words';
import filipinoBadWords from 'filipino-badwords-list';
import md5 from 'md5';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import { ChatFeed, Message } from 'react-chat-ui'
// import { isMobile } from "react-device-detect";
import { IconGoManila, Send } from 'modules/common/components/Icons';
import CustomBubble from './CustomBubble';

// import './chat.css';
import ChatWithUs from '../assets/img/chat-w-us.png';

export {
  firebase
}

const firebaseConfig = process.env.NODE_ENV === 'production' ? {
  apiKey: "AIzaSyDsnJombqAgImzN0wYixu8-QS9znQseiTE",
  authDomain: "msys-megov.firebaseapp.com",
  databaseURL: "https://msys-megov.firebaseio.com",
  projectId: "msys-megov",
  storageBucket: "msys-megov.appspot.com",
  messagingSenderId: "124765713736",
  appId: "1:124765713736:web:c61a05c0c7769bade76f7d",
  measurementId: "G-4J8CLJB6Z2"
} :
  {
    apiKey: "AIzaSyCf3-Ofc2frK3eudh28KizbraEcVGyu9q4",
    authDomain: "gomanila-chat-support.firebaseapp.com",
    databaseURL: "https://gomanila-chat-support.firebaseio.com",
    projectId: "gomanila-chat-support",
    storageBucket: "gomanila-chat-support.appspot.com",
    messagingSenderId: "589497442088",
    appId: "1:589497442088:web:f1c6e2a5433d0b060cba1d",
    measurementId: "G-SQT03R6FFM"
  };
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
export const fbDB = fb.database();

const removeBadWords = new FilterBadWords()
removeBadWords.addWords(...filipinoBadWords.array)

const Chat = ({ onClose }) => {

  const [formData, setFormData] = useState({})
  const [emptyText, setEmptyText] = useState(false)
  const [message, setMessage] = useState({
    id: '',
    message: '',
    senderName: ''
  })

  const user = localStorage.getItem('chat-account') ? JSON.parse(localStorage.getItem('chat-account')) : null

  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (user && user.id && !message.id)
      setMessage({
        id: user.id,
        message: '',
        senderName: user.name
      })
  }, [user])

  const onSubmit = e => {
    e.preventDefault()
    
    if (!user && formData.name) {

      const id = md5(`${formData.name}${random(0, 1000000)}`)
      const roomId = md5(`room${formData.name}${random(0, 1000000)}`)

      setMessage({
        id,
        message: '',
        senderName: formData.name,
        room: roomId
      })

      fbDB.ref(`rooms/${roomId}`).set({
        date_time: firebase.firestore.Timestamp.fromDate(new Date()),
        information: {
          client: formData.name
        },
        messages: [
          new Message({
            id: 'GoManila Helpdesk Support',
            message: `HI ${formData.name}, How can i help you?`,
            senderName: 'GoManila Helpdesk Support',
            date_time: firebase.firestore.Timestamp.fromDate(new Date())
          })
        ]
      })

      return localStorage.setItem('chat-account', JSON.stringify({
        name: formData.name,
        id,
        room: roomId
      }))
    }

    fbDB.ref(`rooms/${user.room}`).update({
      date_time: firebase.firestore.Timestamp.fromDate(new Date())
    })

    fbDB.ref(`rooms/${user.room}/messages`).push({
      ...message,
      message: removeBadWords.clean(message.message),
      date_time: firebase.firestore.Timestamp.fromDate(new Date())
    })

    if (message.message === '') {
      setEmptyText(true)
    }

    return setMessage({
      ...message,
      message: ''
    })
  }

  const onChangeText = e => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (user && user.room) {

      const messageList = fbDB.ref(`rooms/${user.room}/messages`)
      messageList.on('value', (snapshot) => {

        if (snapshot.val())
          setMessages([
            ...messages,
            ...Object.values(snapshot.val())
          ])
      })
    }

  }, [(user && user.room)])

  const onChangeFormData = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <div className="chat-header">
        <IconGoManila />
        <h5 className="flex-grow-1 ml-2 m-0">Chat Support</h5>
        <button
          type='button'
          className='close'
          onClick={onClose}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className="chat-feed-container">
        {user && <ChatFeed
          chatBubble={CustomBubble}
          messages={messages}
          isTyping={false}
          hasInputField={false}
          showSenderName={false}
          bubblesCentered
          bubbleStyles={
            {
              text: {
                fontSize: 14
              },
              chatbubble: {
                borderRadius: 70,
                padding: 10
              }
            }
          }
        />}
        {!user &&
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <img alt="Chat With Us" className="mx-auto" style={{ width: 150, height: 150 }} src={ChatWithUs} />
            <form onSubmit={onSubmit} className="mt-5">
              {!user &&
                <>
                  <label >By placing your Name here&nbsp;<i className="fa fa-arrow-down text-success" /></label>
                  <div className=" d-flex align-items-center">
                    <input
                      style={{ height: 40 }}
                      autoComplete='off'
                      name="name"
                      onChange={onChangeFormData}
                      placeholder="Input your name first"
                      type="text"
                      className="form-control mb-2 chat-input h-12"
                      value={formData.name}
                    />
                    <button className="bg-transparent border-0" type="submit"><Send /></button>
                  </div>
                </>
              }
            </form>
          </div>
        }
      </div>
      {user &&
        <form className="chat-form" onSubmit={onSubmit}>
          <input
            autoFocus
            style={{ maxheight: 120 }}
            autoComplete='off'
            name="message"
            onChange={onChangeText}
            placeholder="Type a message..."
            type="text"
            className="form-control mb-2 chat-input h-12"
            value={message.message}
          />
          <button disabled={emptyText} className="bg-transparent border-0" type="submit"><Send /></button>
        </form>}
    </>
  )
}

Chat.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired
}

export default Chat
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import defaultPhoto from "assets/img/image-placeholder.jpg";
import CmsContext from "CmsContext";
import { getMridRedirectLink } from "modules/transaction/actions";
import Image from "./Image";
import EmptyState from "./EmptyState";
import { getServicesList } from "../../../helper";

function SidebarDrawer({
  profile,
  handleTransact,
  handleButtonBills,
  handleRedirect,
  handleEhelpModal,
  onLogOut,
  _getMridRedirectLink,
}) {
  const servicesList = getServicesList();
  // const servicesList = JSON.parse(localStorage.getItem('services')) || [];
  const cmsContext = useContext(CmsContext);
  const CMS_NAVIGATION_ICONS = cmsContext.findByType("NAVIGATION_ICONS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const handleMridRedirectLink = e => {
    e.preventDefault();

    _getMridRedirectLink({}, async response => {
      if (!_.isEmpty(await response))  window.open(_.get(response, "res.data.redirect_url"));
      return "";
    });
  };

  return (
    <div>
      <div className="profile-wrapper">
        <Image
          className="side-profile-img rounded-circle"
          src={_.get(profile, "photo")}
          defaultSrc={defaultPhoto}
        />
      </div>
      <div className="links-wrapper">
        <button
          type="button"
          className="btn btn-link nav-icons btn-nav-icons"
          onClick={handleRedirect("/")}
        >
          <div className="icn-wrap mr-2">
            <img src={_.get(CMS_NAVIGATION_ICONS, "HOME.cdnUrl")} alt="Home" />
          </div>
          <p>Home</p>
        </button>
        {servicesList && servicesList.length === 0 && (
          <div className="col-12">
            <EmptyState message="Soon to be available" />
          </div>
        )}
        {servicesList &&
          servicesList.length > 0 &&
          servicesList.map(item => (
            <React.Fragment key={item.id}>
              {(_.get(item, "attributes.name") === "E-MRID" && _.get(profile, 'is_resident') === 1) && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleMridRedirectLink}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-MRID.cdnUrl")}
                      alt="E-MRID"
                    />
                  </div>
                  <p>E-MRID</p>
                </button>
              )}
              {_.get(item, "attributes.name") === "E-Government" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleTransact}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-GOVERNMENT.cdnUrl")}
                      alt="E-Government"
                    />
                  </div>
                  <p>E-Government</p>
                </button>
              )}
              {_.get(item, "attributes.name") === "E-Commerce" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleRedirect("/e-commerce")}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-COMMERCE.cdnUrl")}
                      alt="E-Commerce"
                    />
                  </div>
                  <p>E-Commerce</p>
                </button>
              )}
              {_.get(item, "attributes.name") === "E-Bills" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleButtonBills}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-BILLS.cdnUrl")}
                      alt="E-Business"
                    />
                  </div>

                  <p>E-Bills </p>
                </button>
              )}
              {_.get(item, "attributes.name") === "E-Services" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleRedirect("/e-services")}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-SERVICES.cdnUrl")}
                      alt="E-Services"
                    />
                  </div>

                  <p>E-Services</p>
                </button>
              )}
              {_.get(item, "attributes.name") === "E-News" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleRedirect("/news")}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-NEWS.cdnUrl")}
                      alt="E-News"
                    />
                  </div>
                  <p>E-News</p>
                </button>
              )}
              {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false && (
                _.get(item, "attributes.name") === "E-Wallet" && (
                  <button
                    type="button"
                    className="btn btn-link nav-icons btn-nav-icons"
                    onClick={handleRedirect("/e-wallet/cash-in")}
                  >
                    <div className="icn-wrap mr-2">
                      <img
                        src={_.get(CMS_NAVIGATION_ICONS, "E-WALLET.cdnUrl")}
                        alt="E-Wallet"
                      />
                    </div>
                    <p>E-Wallet</p>
                  </button>
                )
              )}
              {_.get(item, "attributes.name") === "E-Help" && (
                <button
                  type="button"
                  className="btn btn-link nav-icons btn-nav-icons"
                  onClick={handleEhelpModal}
                >
                  <div className="icn-wrap mr-2">
                    <img
                      src={_.get(CMS_NAVIGATION_ICONS, "E-HELP.cdnUrl")}
                      alt="E-Help"
                    />
                  </div>
                  <p>E-Help</p>
                </button>
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="side-logout">
        <button
          type="button"
          onClick={onLogOut}
          className="dropdown-item text-dark my-3 px-2"
        >
          <i className="fas fa-power-off" /> Logout
        </button>
      </div>
    </div>
  );
}


SidebarDrawer.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  handleTransact: PropTypes.instanceOf(Function).isRequired,
  handleButtonBills: PropTypes.instanceOf(Function).isRequired,
  handleRedirect: PropTypes.instanceOf(Function).isRequired,
  handleEhelpModal: PropTypes.instanceOf(Function).isRequired,
  onLogOut: PropTypes.instanceOf(Function).isRequired,
  _getMridRedirectLink: PropTypes.instanceOf(Function).isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    _getMridRedirectLink: getMridRedirectLink
  })
]);

export default enhance(SidebarDrawer);
// export default withRouter(SidebarDrawer);

import React, { useContext } from 'react'
import Modal from "modules/common/components/Modal";
import { ModalContext } from "App";
import { useHistory } from 'react-router-dom';
import { HandShakeFilled } from 'modules/common/components/Icons';

function SelectTransactionEhelpModal() {
  const history = useHistory()
  const setModal = useContext(ModalContext);
  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setModal({ isOpen: false });
  };

  const goToLink = (path) => e =>{
    e.preventDefault()
    history.push(path)
    setModal({ isOpen: false });
  }

  return (
    <Modal.Body className='clear-modal transaction-modal'>
      <button
        type='button'
        className='close modal-close'
        onClick={handleOnClose}>
        <span aria-hidden='true'>&times;</span>
      </button>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          <HandShakeFilled className="mr-2"/>
          {' '}
          <h2 className="text-primary cms-text-primary">How can we help?</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-1 mb-md-0">
            <button onClick={goToLink('/e-help/report-incident')} type="button" className="text-center w-100 h-100 py-5 border-0 rounded text-primary cms-text-primary">Report an Incident</button>
          </div>
          <div className="col-12 col-md-6">
            <button onClick={goToLink('/e-help/immunization-registration')} type="button" className="text-center w-100 h-100 py-5 border-0 rounded text-primary cms-text-primary">COVID-19 Electronic Immunization Registration</button>
          </div>
        </div>
        
      </div>
    </Modal.Body>
      
    
  )
}

export default SelectTransactionEhelpModal

import React from "react";
import PropTypes from "prop-types";

function AdvanceList({ data }) {
  return (
    <>
      <div className='col-md-12 d-flex text-center justify-content-center mt-3'>
        <div className='alert alert-success w-100' role='alert'>
          Paid properties for the selected year
        </div>
      </div>
      <div className="table-responsive-sm frame">
        <table className='table table-sm'>
          <thead>
            <tr>
              <th width='450'>PIN</th>
              <th width='450'>Brgy.</th>
              <th width='450'>Survey No.</th>
              <th width='450'>Amount Paid</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => (
              <tr>
                <td>{d.new_pin}</td>
                <td>{d.barangay}</td>
                <td>{d.survey_no}</td>
                <td>{d.initial_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

AdvanceList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default AdvanceList;

/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";

const LoginPinInput = ({ onChange }) => {
  const [value, setValue] = useState("");
  const [eye, setEye] = useState(false);

  const onChangeValue = (val) => (e) => {
    e.preventDefault();

    if (value.length === 4) {
      return false;
    }
    const newValue = `${value}${val}`;
    setValue(newValue);
    onChange(newValue);
    return false;
  };

  const onClearLast = (e) => {
    e.preventDefault();
    const newValue = value.substr(0, value.length - 1);
    setValue(newValue);
    onChange(newValue);
  };

  const toggelEye = (e) => {
    e.preventDefault();
    setEye(!eye);
  };

  const onClear = (e) => {
    e.preventDefault();
    setValue("");
    onChange("");
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col text-center pt-3 pin-inner'>
          <h1
            style={{ letterSpacing: 10, fontSize: 50 }}
            className='text-primary cms-text-primary h-min-100'>
            {value
              ? eye
                ? value
                : value
                    .replace(/\d/, "*")
                    .replace(/\d/, "*")
                    .replace(/\d/, "*")
                    .replace(/\d/, "*")
              : "____"}
          </h1>
          <button
            onClick={toggelEye}
            type='button'
            className='btn btn-primary btn-sm cms-bg-color-primary'>
            {eye ? (
              <>
                <i className='far fa-eye-slash' />
              </>
            ) : (
              <>
                <i className='far fa-eye' />
              </>
            )}
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(7)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            7
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(8)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            8
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(9)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            9
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(4)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            4
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(5)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            5
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(6)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            6
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(1)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            1
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(2)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            2
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(3)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            3
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onClear}
            type='button'
            className='btn btl-lg btn-outline-danger w-100 h-100'>
            Reset
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onChangeValue(0)}
            type='button'
            className='btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold'>
            0
          </button>
        </div>
        <div className='col text-center px-2 py-2'>
          <button
            onClick={onClearLast}
            type='button'
            className='btn btl-lg btn-outline-danger w-100 h-100'>{`<`}</button>
        </div>
      </div>
    </div>
  );
};

LoginPinInput.defaultProps = {};

LoginPinInput.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default LoginPinInput;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as req from "api/actions";
import _ from "lodash";
// import Loader from "modules/common/components/Loader";
import * as c from "../constants";
import * as actions from "../actions";

export const mapStateToProps = ({ api }) => ({
  auth: _.get(api, c.AUTH) || {},
});

export const hocComponent = (WrappedComponent) => {
  function withAuth(props) {
    useEffect(() => {
      const token = localStorage.getItem("token");
      props.setItem(c.AUTH, {
        token,
        isAuthenticated: !!token,
      });
    }, []);

    const { getProfile, auth } = props;
    const isAuthenticated = _.get(auth, "item.isAuthenticated");
    
    if (isAuthenticated && window.location.href.indexOf('gmp_sso') < 0) {
      getProfile();
    }

    // if (_.isEmpty(auth)) return <Loader />;
    return <WrappedComponent {..._.omit(props, ["setItem"])} />;
  }
  withAuth.propTypes = {
    auth: PropTypes.instanceOf(Object).isRequired,
    setItem: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
  };
  return withAuth;
};

export default (WrapperComponent) =>
  connect(mapStateToProps, {
    ...actions,
    ..._.pick(req, ["setItem"]),
  })(hocComponent(WrapperComponent));

/* global my */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { get } from "session";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import EmptyState from "modules/common/components/EmptyState";
import Select from "react-select";
import {
  reactSelectCustomStyle,
  handleOpenPgiUrl,
  scrollToTop,
  payloadToUpperCase,
  numberFormat
} from "helper";
import InputMask from "react-input-mask";
import Alert from 'react-s-alert';
import useFormValidator from "useFormValidator";
import { ModalContext } from "App";
import GCashIcon from "assets/img/gcash-white.png";
// import CmsContext from "CmsContext";
import { VALID_IDS } from "constant";
// import PaymentGatewayIframeModalContent from 'modules/common/components/PaymentGatewayIframeModalContent';
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import * as actions from "./actions";
import * as c from "./constants";
import * as lookUps from "../../../../constants";
import { generatePGI } from "../../../../actions";

const BTAXSearchManual = ({
  searchBilling,
  searching,
  billingData,
  clearItem,
  create,
  processPGI,
  PGIgenerating,
  creating,
  module,
  processGetTransactionHistoryList,
  isGCashLogin,
  setShowTransactionType,
  inquireYears,
  yearList,
}) => {
  const history = useHistory();
  const setModal = useContext(ModalContext);
  // const cmsContext = useContext(CmsContext);
  // const CMS_MASKINGS = cmsContext.findByType("MASKINGS");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    bin: "",
    year: "",
  });

  const [periodFrom, setPeriodFrom] = useState(1)

  const disableOption = index => periodFrom > index ? 'yes' : ''

  const periodOptions = [
    {
      label: `${_.get(formData, 'year')} - 1st Quarter`,
      value: 1,
      disabled: disableOption(1),
    },
    {
      label: `${_.get(formData, 'year')} - 2nd Quarter`,
      value: 2,
      disabled: disableOption(2),
    },
    {
      label: `${_.get(formData, 'year')} - 3rd Quarter`,
      value: 3,
      disabled: disableOption(3),
    },
    {
      label: `${_.get(formData, 'year')} - 4th Quarter`,
      value: 4,
      disabled: disableOption(4),
    },
  ];

  const validateForm = useFormValidator(
    formData,
    {
      bin: "required",
      year: "required"
    },
    {
      bin: {
        required: "Business Identification Number"
      },
      year: {
        required: "Year"
      }
    }
  );
  

  useEffect(() => {
    validateForm.onSetObjectRules({
      // bin: `required|mask:${_.get(CMS_MASKINGS, "BTAX_BIN_REGEX_CSV")}~${_.get(
      //   CMS_MASKINGS,
      //   "BTAX_BIN_CSV"
      // )}`
      bin: 'required',
      year: 'required',
    });
  }, []);

  const validateSubmit = useFormValidator(formData, {
    valid_id_to_present: "required"
  });

  useEffect(() => {
    if(_.get(billingData, 'attributes.type') === "RENEWAL") {
      validateSubmit.onSetObjectRules({
        qtr_to: "required",
        valid_id_to_present: "required"
      });
    } else {
      validateSubmit.onSetObjectRules({
        valid_id_to_present: "required"
      });
    }
    setPeriodFrom(_.get(billingData, 'attributes.payment_from')?.replace(`${_.get(formData, 'year')} - `, ""))
  }, [billingData]);

  useEffect(() => {
    scrollToTop();
  }, [isSubmitted]);

  useEffect(() => {
    validateForm.validate();
    validateSubmit.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setShowTransactionType(false);
      setFormData({});
      clearItem({});
    };
  }, []);

  useEffect(() => {
    if( _.get(formData, 'qtr_to') || _.get(formData, 'qtr_from')){
    const finalPayload = {
      ...payloadToUpperCase({
        ...formData
      }),
    };
    searchBilling(finalPayload, () => {
      setIsSubmitted(true);
      setShowTransactionType(true);
    });
    };
  }, [_.get(formData, 'qtr_to'),_.get(formData, 'qtr_from') ])

  const handleInquireYears = () => {
    const args = {
      bin: _.get(formData, 'bin'),
    };

    if (!_.isEmpty(_.get(formData, 'bin'))) {
      setTimeout(() => {
        inquireYears(args, (data) => {
          if (!data) {
            Alert.error("No unpaid years found.");
          }
        },

        setFormData({
          ...formData,
          year: '',
        }));
      }, 200);
    }
  };

  const onChangeInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      year: "",
    });
  };

  const onChangeSelect = name => e => {
    setFormData({
      ...formData,
      [name]: e.value
    });
  };

  const onSearchBilling = e => {
    e.preventDefault();
    if (!validateForm.response.is_passed) {
      return false;
    }
    const finalPayload = {
      ...payloadToUpperCase({
        ...formData
      }),
    };
    searchBilling(finalPayload, (res) => {
      setIsSubmitted(true);
      setShowTransactionType(true);
      if(res.data.attributes.type === "RENEWAL") {
        setFormData({
          ...formData,
          qtr_to: 4
        })
      }
    });

    return true;
  };

  const formatPeriod = data => {
    return data
      ? data
          .replace("- 1", " - 1st quarter")
          .replace("- 2", " - 2nd quarter")
          .replace("- 3", " - 3rd quarter")
          .replace("- 4", " - 4th quarter")
      : "---";
  };

  const onBack = e => {
    e.preventDefault();
    setIsSubmitted(false);
    setShowTransactionType(false);
    clearItem({});
  };

  const onSubmitAndPay = e => {
    e.preventDefault();

    const finalPayload = {
      ...payloadToUpperCase({
        ...formData,
        application_status_id: _.get(module, "application_status_id")
      }),
      valid_id_to_present: _.get(formData, "valid_id_to_present")
    };

    const source = JSON.parse(localStorage.getItem("source"));

    create(finalPayload, response => {
      processGetTransactionHistoryList();
      processPGI(
        _.get(response, "data.relationships.transaction_history.data.id"),
        { source },
        (url, res) => {
          if (window.my && isGCashLogin) {
            my.postMessage({
              url: _.get(
                res,
                "included.gcashMiniProgramLogs.attributes.response.data.actionForm.redirectionUrl"
              )
            });
          } else {
            handleOpenPgiUrl(url);
          }

          setModal({
            isOpen: false
          });

          setIsSubmitted(false);
          setShowTransactionType(false);
          setFormData({});
          clearItem({});
          history.push("/success-page");
        }
      );
    });
  };

  const payLabel = isGCashLogin ? `Pay with GCASH` : `Pay Now`;
  // render for BTAX Billing

  const yearOptions = [];

  Object.entries(yearList).forEach(([, val]) => {
    yearOptions.push({
      label: val,
      value: val
    })
  });

  return (
    <>
      {!isSubmitted && _.isEmpty(billingData) && (
        <form onSubmit={onSearchBilling}>
          <div className="form-wrapper mb-3 py-3  animated fadeIn">
            <div className="full-group ">
              <div className="form-row">
                <div className="form-input-group col-12">
                  <label
                    htmlFor="last_name"
                    className="full-label mb-0 cms-text-muted-dark"
                  >
                    Business Identification Number{" "}
                    {validateForm.renderMessage("bin")}
                  </label>
                  <InputMask
                    // placeholder={_.get(CMS_MASKINGS, "BTAX_BIN_CSV")}
                    className="full-input text-uppercase"
                    maskChar=" "
                    // mask={_.get(CMS_MASKINGS, "BTAX_BIN_CSV")}
                    autoComplete="none"
                    id="bin"
                    name="bin"
                    onChange={onChangeInput}
                    value={_.get(formData, "bin") || ""}
                  />
                </div>
              </div>
              {/* <div className="form-row">
                <div className="form-input-group col-12">
                  <label
                    htmlFor="last_name"
                    className="full-label mb-0 cms-text-muted-dark"
                  >
                    Year{" "}
                    {validateForm.renderMessage("year")}
                  </label>
                  <input
                    className="full-input text-uppercase"
                    autoComplete="none"
                    id="year"
                    name="year"
                    onChange={onChangeInput}
                    value={_.get(formData, "year") || ""}
                  />
                </div>
              </div> */}
              <div className="form-row">
                <div className="form-input-group col-12">
                  <label
                    htmlFor="last_name"
                    className="full-label mb-0 cms-text-muted-dark"
                  >
                    Year{" "}
                    {validateForm.renderMessage("year")}
                  </label>
                  <Select
                    styles={reactSelectCustomStyle}
                    value={
                      yearOptions.find(
                        item =>
                          _.get(formData, "year") ===
                          _.get(item, "value")
                      ) || ""
                    }
                    onChange={onChangeSelect(
                      "year"
                    )}
                    options={yearOptions}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onFocus={handleInquireYears}
                  />
                </div>
              </div>
            </div>
            <div className="full-group pt-5">
              <button
                disabled={searching || !validateForm.response.is_passed}
                type="submit"
                className="btn btn-primary btn-md w-100 cms-bg-color-primary cms-outline-primary"
              >
                {searching ? <i className="fa fa-spinner fa-spin" /> : `Inquire`}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmitted && _.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <EmptyState message="No Record Found" />
          <div className="full-group">
            <button
              onClick={onBack}
              type="button"
              className="btn btn-primary btn-md w-100"
            >
              Back
            </button>
          </div>
        </>
      )}
      {isSubmitted && !_.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          {/* mobile */}
          <div className="d-md-none">
            <div className="animated fadeIn">
              <div className="row">
                <div className="col-12 text-left text-black">
                  Business Identification Number (BIN)
                </div>
                <div className="col-12 text-left cms-text-primary">
                  <b>{_.get(billingData, "attributes.bin") || "---"}</b>
                </div>
              </div>
            </div>
            {_.get(billingData, 'attributes.type') === 'RENEWAL' ? (
              <div className="full-group ">
                {/* <div className="form-row">
                  <div className="form-input-group col-12">
                    <label
                      htmlFor="last_name"
                      className="full-label mb-0 cms-text-muted-dark"
                    >
                      From{" "}
                      {validateSubmit.renderMessage("qtr_from")}
                    </label>
                    <Select
                      styles={reactSelectCustomStyle}
                      value={
                        periodOptions.find(
                          item =>
                            _.get(formData, "qtr_from") ===
                            _.get(item, "value")
                        ) || ""
                      }
                      onChange={onChangeSelect(
                        "qtr_from"
                      )}
                      options={periodOptions}
                      isOptionDisabled={option => option.disabled === 'yes'}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-input-group col-12">
                    <label
                      htmlFor="last_name"
                      className="full-label mb-0 cms-text-muted-dark"
                    >
                      Select Quarter{" "}
                      {validateSubmit.renderMessage("qtr_to")}
                    </label>
                    <Select
                      styles={reactSelectCustomStyle}
                      value={
                        periodOptions.find(
                          item =>
                            _.get(formData, "qtr_to") ===
                            _.get(item, "value")
                        ) || ""
                      }
                      onChange={onChangeSelect(
                        "qtr_to"
                      )}
                      options={periodOptions}
                      isOptionDisabled={option => option.disabled === 'yes'}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : <></> }
            <br />
            <div className="form-wrapper mb-3">
              <div className="full-group">
                <div className="form-row">
                  <div className="container cms-bg-color-muted p-2">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">
                          ACCOUNT NAME
                        </div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-black text-right font-weight-light">
                          {_.get(billingData, "attributes.account_name").toUpperCase() ||
                            "---"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container span-bg">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">FROM</div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-uppercase text-black text-right font-weight-light">
                          {formatPeriod(
                            _.get(billingData, "attributes.payment_from")
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container cms-bg-color-muted p-2">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">TO</div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-uppercase text-black text-right font-weight-light">
                          {formatPeriod(
                            _.get(billingData, "attributes.payment_to")
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container span-bg">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">
                          ASSESSMENT DATE
                        </div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-uppercase text-black text-right font-weight-light">
                          {_.get(billingData, "attributes.assessment_date")
                            ? moment(
                                `${_.get(
                                  billingData,
                                  "attributes.assessment_date"
                                )}`
                              ).format("MMM DD, YYYY")
                            : "---"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container cms-bg-color-muted p-2">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">
                          EXPIRATION DATE
                        </div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-uppercase text-black text-right font-weight-light">
                          {_.get(billingData, "attributes.expiration_date")
                            ? moment(
                                `${_.get(
                                  billingData,
                                  "attributes.expiration_date"
                                )}`
                              ).format("MMM DD, YYYY")
                            : "---"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container span-bg">
                    <div className="row align-items-center form-input-group ml-0 mr-0">
                      <div className="col-6 full-label">
                        <div className="font-weight-bold text-black">
                          Total (including VAT)
                        </div>
                      </div>
                      <div className="col-6 full-label">
                        <div className="text-uppercase text-black text-right">
                          <b>{_.get(billingData, "attributes.billing_amount")}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* web */}
          <div className="d-none d-md-block">
            <div className="card  animated fadeIn">
              <div className="row">
                <div className="col-12 text-center text-black">
                  Business Identification Number (BIN)
                </div>
                <div className="col-12 text-center cms-text-primary">
                  <b>{_.get(billingData, "attributes.bin") || "---"}</b>
                </div>
              </div>
            </div>
            {_.get(billingData, 'attributes.type') === 'RENEWAL' ? (
              <div className="full-group ">
                {/* <div className="form-row">
                  <div className="form-input-group col-12">
                    <label
                      htmlFor="last_name"
                      className="full-label mb-0 cms-text-muted-dark"
                    >
                      From{" "}
                      {validateSubmit.renderMessage("qtr_from")}
                    </label>
                    <Select
                      styles={reactSelectCustomStyle}
                      value={
                        periodOptions.find(
                          item =>
                            _.get(formData, "qtr_from") ===
                            _.get(item, "value")
                        ) || ""
                      }
                      onChange={onChangeSelect(
                        "qtr_from"
                      )}
                      options={periodOptions}
                      isOptionDisabled={option => option.disabled === 'yes'}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-input-group col-12">
                    <label
                      htmlFor="last_name"
                      className="full-label mb-0 cms-text-muted-dark"
                    >
                      Select Quarter{" "}
                      {validateSubmit.renderMessage("qtr_to")}
                    </label>
                    <Select
                      styles={reactSelectCustomStyle}
                      value={
                        periodOptions.find(
                          item =>
                            _.get(formData, "qtr_to") ===
                            _.get(item, "value")
                        ) || ""
                      }
                      onChange={onChangeSelect(
                        "qtr_to"
                      )}
                      options={periodOptions}
                      isOptionDisabled={option => option.disabled === 'yes'}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : <></> }
            <br />
            <table className="table tabl-sm table-borderless table-sm mb-0">
              <tbody>
                <tr className="cms-bg-color-muted">
                  <td style={{ width: 150 }} className="pl-3">
                    <b>ACCOUNT NAME</b>
                  </td>

                  <td className="text-right pr-3">
                    {_.get(billingData, "attributes.account_name").toUpperCase() || "---"}
                  </td>
                </tr>
                <tr>
                  <td className="pl-3">
                    <b>FROM</b>
                  </td>
                  <td className="text-right pr-3">
                    {formatPeriod(
                      _.get(billingData, "attributes.payment_from")
                    )}
                  </td>
                </tr>
                <tr className="cms-bg-color-muted">
                  <td className="pl-3">
                    <b>TO</b>
                  </td>
                  <td className="text-right pr-3">
                    {formatPeriod(
                      _.get(billingData, "attributes.payment_to")
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pl-3">
                    <b>ASSESSMENT DATE</b>
                  </td>
                  <td className="text-right pr-3">
                    {_.get(billingData, "attributes.assessment_date")
                      ? moment(
                          `${_.get(billingData, "attributes.assessment_date")}`
                        ).format("MMM DD, YYYY")
                      : "---"}
                  </td>
                </tr>
                <tr className="cms-bg-color-muted">
                  <td className="pl-3">
                    <b>EXPIRATION DATE</b>
                  </td>
                  <td className="text-right pr-3">
                    {_.get(billingData, "attributes.expiration_date")
                      ? moment(
                          `${_.get(billingData, "attributes.expiration_date")}`
                        ).format("MMM DD, YYYY")
                      : "---"}
                  </td>
                </tr>
                <tr>
                  <td width="250px" className="pl-3">
                    <b>Total (including VAT)</b>
                  </td>
                  <td className="text-right pr-3">
                    <b>₱ {numberFormat(_.get(billingData, "attributes.billing_amount"), 2)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="full-group my-5">
            <div className="form-row">
              <div className="form-input-group col-12">
                <label
                  htmlFor="last_name"
                  className="full-label mb-0 cms-text-muted-dark"
                >
                  Valid ID to Present Upon Claiming{" "}
                  {validateSubmit.renderMessage("valid_id_to_present")}
                </label>
                <Select
                  styles={reactSelectCustomStyle}
                  placeholder="Select"
                  value={
                    VALID_IDS.find(
                      item =>
                        _.get(formData, "valid_id_to_present") ===
                        _.get(item, "value")
                    ) || ""
                  }
                  onChange={onChangeSelect("valid_id_to_present")}
                  options={VALID_IDS}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="alert alert-primary text-center mt-3" role="alert">
            Note: An additional convenience fee will be charged to use this
            e-payment service, and once a reference number is generated, please
            pay with in the day.
          </div>
          <hr /> */}
          <div className="row">
            <div className="col-12 col-md-6 mb-1 mb-md-0 pr-md-1">
              <button
                disabled={PGIgenerating || creating}
                onClick={onBack}
                type="button"
                className="btn button-cancel btn-md w-100"
              >
                Back
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button
                onClick={onSubmitAndPay}
                disabled={
                  PGIgenerating ||
                  creating ||
                  !validateSubmit.response.is_passed
                }
                type="submit"
                className={`btn btn-success cms-bg-color-primary btn-md w-100 d-flex align-items-center ${
                  isGCashLogin ? "gcash-icon" : ""
                }`}
              >
                {isGCashLogin && (
                  <img src={GCashIcon} alt="GCash" width="22" height="22" />
                )}
                {PGIgenerating || creating ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <p className="flex-grow-1 d-flex align-items-center justify-content-center m-0">
                    {payLabel}
                  </p>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

BTAXSearchManual.propTypes = {
  processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  searchBilling: PropTypes.instanceOf(Function).isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  searching: PropTypes.bool.isRequired,
  billingData: PropTypes.instanceOf(Object).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  processPGI: PropTypes.instanceOf(Function).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  isGCashLogin: PropTypes.bool.isRequired,
  setShowTransactionType: PropTypes.instanceOf(Object).isRequired,
  inquireYears: PropTypes.instanceOf(Function).isRequired,
  yearList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  searching: api.loading.indexOf(c.SEARCH_BILLING) > -1,
  billingData: _.get(api, `${c.SEARCH_BILLING}.item`) || {},
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
  isGCashLogin: !!_.get(JSON.parse(get("profile")), "attributes.is_gcash_login"),
  yearList: _.get(api, `${c.INQUIRE_YEARS}.res.data`) || [],
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList
  })
]);

export default enhance(BTAXSearchManual);

import * as req from 'api/actions';
import * as c from './constants';

export const test = {}

export const searchBilling = (payload, callback) => req.findPost(
  c.SEARCH_BILLING,
  '/api/v1/qpax_inquiry',
  payload,
  callback
);

export const create = (payload, callback) => req.create(
  c.CREATE, 
  '/api/v1/taxpayers/notice_of_violations',
  payload, 
  callback)

export const clearItem = (data) => req.setItem(c.SEARCH_BILLING, data)
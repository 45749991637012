export const MODULE = "BPLS";

export const CREATE_BUSINESS_NEW_TRANSACTION = `${MODULE}/create_new_bpls`;
export const GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS =
  "TRANSACTION/get_selected_module_transaction_requirements";

  // bpls/btax
  export const SEARCH_BILLING = `${MODULE}/search_billing`;
  export const CREATE = `${MODULE}/create_bpls`;

  // bpls eboss resubmit renew
  export const UPDATE_BUSINESS_RENEW_TRANSACTION_RESUBMIT =
  "TRANSACTION/update_business_renew_transaction_resubmit";

  // bplsNewE2E
// export const CREATE_NEW_APPLICATION_TRANSACTION = 'CREATE_NEW_APPLICATION_TRANSACTION/create_new_application_transaction'

export const CREATE_NEW_E2E_BUSINESS_APPLICATION = "CREATE_NEW_E2E_BUSINESS_APPLICATION/create_new_e2e_business_application"
export const CREATE_NEW_E2E_BUSINESS_APPLICATION_RESUBMIT = "CREATE_NEW_E2E_BUSINESS_APPLICATION_RESUBMIT/create_new_e2e_business_application_resubmit"
export const GET_PER_PAGE_VALIDATION = "GET_PER_PAGE_VALIDATION/get_per_page_validation"
// application summary preview NEW
export const BPLS_E2E_PREVIEW_URL = "/api/v1/bpls/taxpayer/business/permit/preview_application_new"
// application summary preview RENEW
export const BPLS_E2E_PREVIEW_URL_RENEWAL = "/api/v1/bpls/taxpayer/business/permit/preview_application_renewal"
export const GET_NECESSARY_FIELD_LIST = `GET_NECESSARY_FIELD_LIST/get_necessary_field_list`;

// bplsRenewE2E
export const CREATE_RENEW_E2E_BUSINESS_APPLICATION = "CREATE_RENEW_E2E_BUSINESS_APPLICATION/create_renew_e2e_business_application"
export const CREATE_RENEW_E2E_BUSINESS_APPLICATION_RESUBMIT = "CREATE_RENEW_E2E_BUSINESS_APPLICATION_RESUBMIT/create_renew_e2e_business_application_resubmit"
// bplsChangeE2E
export const CREATE_CHANGE_E2E_BUSINESS_APPLICATION = "CREATE_CHANGE_E2E_BUSINESS_APPLICATION/create_change_e2e_business_application"
export const CREATE_CHANGE_E2E_BUSINESS_APPLICATION_RESUBMIT = "CREATE_CHANGE_E2E_BUSINESS_APPLICATION_RESUBMIT/create_change_e2e_business_application_resubmit"
export const GET_PER_PAGE_VALIDATION_CHANGE = "GET_PER_PAGE_VALIDATION_CHANGE/get_per_page_validation_change"
export const GET_BUSINESS_VALIDATION_CHANGE = "GET_BUSINESS_VALIDATION_CHANGE/get_business_validation_change"
// bplsQuarterly
export const QUARTERLY_INQUIRY = "QUARTERLY_INQUIRY"
export const CREATE_QUARTERLY_E2E_BUSINESS_APPLICATION = "CREATE_QUARTERLY_E2E_BUSINESS_APPLICATION/create_quarterly_application"

// bplsDelinquent
export const DELINQUENT_INQUIRY = "DELINQUENT_INQUIRY"
export const CREATE_DELINQUENT_E2E_BUSINESS_APPLICATION = "CREATE_DELINQUENT_E2E_BUSINESS_APPLICATION/create_delinquent_application"
export const RESUBMIT_DELINQUENT_E2E_BUSINESS_APPLICATION = "RESUBMIT_DELINQUENT_E2E_BUSINESS_APPLICATION/resubmit_delinquent_application"

export const GET_BUSINESS_LIST = "BPLS/GET_BUSINESS_LIST";
export const SHOW_BUSINESS = 'BPLS/SHOW_BUSINESS';

// bplsRetirement
export const CREATE_RETIREMENT_E2E_BUSINESS_APPLICATION = "CREATE_RETIREMENT_E2E_BUSINESS_APPLICATION/create_retirement_application"
export const RESUBMIT_RETIREMENT_E2E_BUSINESS_APPLICATION = "RESUBMIT_RETIREMENT_E2E_BUSINESS_APPLICATION/resubmit_retirement_application"

export const FORM_DATA = {
  tax_payer_first_name: "",
  tax_payer_middle_name: "",
  tax_payer_last_name: "",
  extension_name: "",
  is_representative: 0,
  capital_amount: "",
  business_permit_requirements: []
};

export const RENEW_FORM_DATA = {
  tax_payer_first_name: "",
  tax_payer_middle_name: "",
  tax_payer_last_name: "",
  extension_name: "",
  is_representative: 0,
  gross_amount: "",
  bin: "",
  business_permit_requirements: []
};

export const VALID_IDS = [
  {
    label: "PASSPORT",
    value: "PASSPORT",
  },
  {
    label: "SSS",
    value: "SSS",
  },
  {
    label: "UMID",
    value: "UMID",
  },
  {
    label: "PHILHEALTH",
    value: "PHILHEALTH",
  },
  {
    label: "DRIVER'S LICENSE",
    value: "DRIVER'S LICENSE",
  },
  {
    label: "VOTER'S ID",
    value: "VOTER'S ID",
  },
  {
    label: "SENIOR CITIZEN's ID",
    value: "SENIOR CITIZEN's ID",
  },
  {
    label: "POSTAL ID",
    value: "POSTAL ID",
  },
  {
    label: "BARANGAY ID",
    value: "BARANGAY ID",
  },
  {
    label: "Authorization Letter",
    value: "Authorization Letter",
  },
];

export const DOWNLOAD_FORMS = "TBPLS/download_forms";
export const DOWNLOAD_FORMS_E2E = "DOWNLOAD_FORMS_E2E/download_forms_e2e";

export const FORM_OWNERSHIP = [
  {
    label: "Sole Proprietorship",
    value: "SOLE PROPRIETORSHIP",
  },
  {
    label: "One person Corporation",
    value: "ONE PERSON CORPORATION",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Corporation",
    value: "CORPORATION",
  },
];

export const FORM_OWNERSHIP_E2E = [
  {
    label: "Sole Proprietorship",
    value: "SOLE PROPRIETORSHIP",
  },
  {
    label: "One person Corporation",
    value: "ONE PERSON CORPORATION",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Corporation",
    value: "CORPORATION",
  },
  {
    label: "Cooperative",
    value: "COOPERATIVE",
  },
];

export const GENDER_OPTION = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];

// new E2E /SEC/CDA Registration 
export const E2E_FORM_DATA = {
  // renew summary
  id: "",
  total_business_activities_gross_amount: "",
  business_id: "",
  // tab 0
  kind_of_ownership: "",
  owners_gender: "",
  dti_no: "",
  sec_no: "",
  cda_no: "",
  tin_no: "",
  business_name: "",
  trade_name: "",
  incharge_first_name: "",
  incharge_middle_name: "",
  incharge_last_name: "",
  incharge_suffix_name: "",
  nationality: "",
  cellphone_no: "",
  telephone_no: "",
  email_address: "",
  // tab 1
  main_office_street: "",
  main_office_barangay_code: "",
  main_office_municipality_code: "",
  main_office_province_code: "",
  main_office_region_code: "",
  main_office_zip_code: "",
  pinned_geolocation: "",
// tab 2
  area: "",
  no_of_male_employees: "",
  no_of_female_employees: "",
  no_of_employees: 0, // # of male + # of fmale : will be sent to API
  no_of_employees_residing_manila: "",
  no_of_van: "",
  no_of_truck: "",
  no_of_motorcycle: "",
  is_same_as_business_address: 0, //
  street: "",
  zip_code: "",
  barangay_code: "",
  municipality_code: "",
  province_code: "",
  region_code: "",
  location_owned: 0,
  tdn_no: "",
  pin_no: "",
  is_tdn_selected: 0, //
  is_pin_selected: 0, //
  lessor_name: "",
  monthly_rental: "",
  with_tax_incentives: 0,
  tax_incentives_attachment_url: "",
  // tab3
  activity_type: "",
  activity_type_others: "", //
  activities: [],
  // tab 4
  is_representative: 0,
  valid_id_to_present: "",
  requirements: [],
  // summary
  terms_and_conditions_accepted: "",
  // delinquent inquiry
  inquiry_data: {},
};

 // tab3:activities 
export const BUSINESS_ACTIVITIES = {
  line_of_business: '',
  industrial_code: '',
  service: '',
  no_of_units: '',
  capital_amount: '',
  // renewal
  gross_amount: '',
  activity_id: '',
}

// bpls stepper default no.
export const TAB_NO = 0

export const PAGE_TAB = "PAGE_TAB/page_tab"
export const SECONDARY_TAB = "SECONDARY_TAB/secondary_tab"
export const IS_BUSINESS_INCOMPLETE = "IS_BUSINESS_INCOMPLETE/is_business_incomplete"

export const SHOW_EDIT_MODAL = false
export const VALIDATE_RENEWAL_ACTIVITIES = "VALIDATE_RENEWAL_ACTIVITIES/validate_renewal_activities"
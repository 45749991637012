import React from "react";
import PropTypes from "prop-types";

function DelinquentList({ data }) {
  return (
    <>
      <div className='col-md-12 d-flex text-center justify-content-center mt-3'>
        <div className='alert alert-danger w-100' role='alert'>
          Delinquent Properties - please go to the Municipal Office to resolve
          these delinquency cases of your properties.
        </div>
      </div>
      <div className="table-responsive-sm frame">
        <table className='table table-sm'>
          <thead>
            <tr>
              <th width='450'>PIN</th>
              <th width='450'>Brgy.</th>
              <th width='450'>Survey No.</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => (
              <tr>
                <td width='450'>{d.new_pin}</td>
                <td width='450'>{d.barangay}</td>
                <td width='450'><span className="value-table">{d.survey_no} </span></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

DelinquentList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default DelinquentList;

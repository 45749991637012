import React from "react";
import PropTypes from "prop-types";

function PropertyInfo({ data }) {
  const formatPeriod = (d) => {
    let val = "";
    switch (d) {
      case 0:
        val = "1st Quarter";
        break;
      case 1:
        val = "1st Quarter";
        break;
      case 2:
        val = "2nd Quarter";
        break;
      case 3:
        val = "3rd Quarter";
        break;
      case 4:
        val = "4th Quarter";
        break;
      default:
        val = "";
        break;
    }
    return val;
  };
  return (
    <table className='table table-sm table-striped table-sm mb-0 border-bottom mt-3'>
      <tbody>
        <tr>
          <td>AMOUNT</td>
          <td style={{ width: 20 }}>:</td>
          <td>
            <b>{data.total_human}</b>
          </td>
        </tr>
        <tr>
          <td style={{ width: 150 }}>ARP</td>
          <td style={{ width: 20 }}>:</td>
          <td>{data.new_arp}</td>
        </tr>
        <tr>
          <td style={{ width: 150 }}>PIN</td>
          <td style={{ width: 20 }}>:</td>
          <td>{data.new_pin}</td>
        </tr>
        <tr>
          <td style={{ width: 150 }}>TAX YEAR</td>
          <td style={{ width: 20 }}>:</td>
          <td>{data.tax_year}</td>
        </tr>
        {/* <tr>
          <td>FROM</td>
          <td style={{ width: 20 }}>:</td>
          <td>test</td>
        </tr>
        <tr>
          <td>TO</td>
          <td style={{ width: 20 }}>:</td>
          <td>test</td>
        </tr> */}
        <tr>
          <td>PERIOD PROCESSED</td>
          <td style={{ width: 20 }}>:</td>
          <td>
            <b>{formatPeriod(data.period)}</b>
          </td>
        </tr>
        {/* <tr>
          <td>EXPIRATION DATE</td>
          <td style={{ width: 20 }}>:</td>
          <td>
            <b>test</b>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}

PropertyInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default PropertyInfo;

import * as req from "api/actions";
import _ from "lodash";
import * as c from "./constants";

export const getTransactionHistoryList = (params, callback) =>
  req.list(
    c.GET_TRANSACTION_HISTORY_LIST,
    "/api/v1/taxpayers/transaction_histories",
    params,
    callback
  );

export const getSelectedTransactionHistory = (id, params, callback) =>
  req.item(
    c.GET_SELECTED_TRANSACTION_HISTORY,
    `/api/v1/taxpayers/transaction_histories/${id}`,
    params,
    callback
  );

export const clearSelectedTransactionHistory = () =>
  req.setItem(c.GET_SELECTED_TRANSACTION_HISTORY, {});

export const generatePGI = (id, payload, callback) =>
  req.create(
    c.GENERATE_PGI,
    `/api/v1/taxpayers/paymentgate/${id}`,
    payload,
    ({ data }) => {
      const url = _.get(data, "attributes.url");
      if (callback) callback(url, data);
    },
    {
      defaultMessage: "Redirecting to Payment Gateway..."
    }
  );

export const uploadPaymentReceipt = (id, payload, callback) =>
  req.update(
    c.UPLOAD_PAYMENT_RECEIPT,
    `/api/v1/taxpayers/transaction_histories/${id}/payment_receipt`,
    payload,
    ({ data }) => {
      if (callback) callback(data);
    }
  );

export const updateUploadOVRS = (id, payload, callback) =>
  req.upload(
    c.UPDATE_OVRS,
    `/api/v1/taxpayers/ordinance_violation_receipts/${id}`,
    payload,
    callback
  );

export const updateOVRS = (id, payload, callback) =>
  req.update(
    c.UPDATE_OVRS,
    `/api/v1/taxpayers/ordinance_violation_receipts/${id}`,
    payload,
    callback
  );

export const checkOVRS = (params, callback) =>
  req.item(
    c.CHECK,
    "/api/v1/taxpayers/ordinance_violation_receipts/check/ovr",
    callback,
    params
  );

export const getAppointments = (params, callback) =>
  req.list(
    c.GET_APPOINTMENTS,
    `/api/v1/taxpayers/appointments`,
    params,
    callback
  );

// export const updateBusinessTransaction = (id, payload, callback) =>
//   req.update(
//     c.UPDATE_BUSINESS_NEW_TRANSACTION,
//     `/api/v1/taxpayers/business_permits/${id}`,
//     payload,
//     callback
//   );

// new resubmit
export const updateBusinessTransaction = (id, payload, callback) =>
  req.update(
    c.UPDATE_BUSINESS_NEW_TRANSACTION,
    `/api/temp/taxpayers/business_permits/${id}`,
    payload,
    callback
  );

export const getSelectedModuleTransactionRequirements = id =>
  req.list(
    c.GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS,
    `/api/v1/taxpayers/application_statuses/${id}/requirements`
  );
export const updateBusinessTransactionRenewal = (id, payload, callback) =>
  req.update(
    c.UPDATE_BUSINESS_NEW_TRANSACTION_RENEWAL,
    `/api/v1/taxpayers/business_permit_renewals/${id}`,
    payload,
    callback
  );

export const clearItem = data =>
  req.setItem(c.UPDATE_BUSINESS_NEW_TRANSACTION_RENEWAL, data);

export const fetchBilling = (id, payload, callback) =>
  req.create(
    c.FETCH_BILLING,
    `/api/temp/taxpayers/business_permits/${id}/recalculate`,
    payload,
    callback,
    {
      defaultMessage: false
    }
  );

export const confirmBilling = (id, payload, callback) =>
  req.create(
    c.CONFIRM_BILLING,
    `/api/temp/taxpayers/business_permits/${id}/regenerate`,
    payload,
    callback
  );

export const fetchBillingRPTax = (id, params, callback) =>
  req.item(
    c.FETCH_BILLING_RPTAX,
    `/api/v1/taxpayers/real_property_taxes/billings_from_api_imports/${id}/inquire_quarter`,
    callback,
    params
  );

export const confirmBillingRPTax = (id, payload, callback) =>
  req.update(
    c.CONFIRM_BILLING_RPTAX,
    `/api/v1/taxpayers/real_property_taxes/billings_from_api_imports/${id}/regenerate`,
    payload,
    callback
  );

// Select Quarter E2E
export const fetchBillingE2E = (id, payload, callback) =>
  req.create(
    c.FETCH_BILLING_E2E,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/inquire_quarter`,
    payload,
    callback,
    {
      defaultMessage: false
    }
  );

export const confirmBillingE2E = (id, payload, callback) =>
  req.create(
    c.CONFIRM_BILLING_E2E,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/select_quarter`,
    payload,
    callback
  );

export const viewSoa = (uri, params, callback) =>
  req.item(c.VIEW_SOA, uri, params, callback);

// regenerate soa
export const regenerateSoa = (id, payload, callback) =>
  req.create(
    c.REGENERATE_SOA,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/regenerate_soa`,
    payload,
    callback
  );

export const requestRefund = (payload, callback) =>
  req.create(
    c.REQUEST_REFUND,
    `/api/v1/taxpayers/gcash_refund`,
    payload,
    callback
  );

export const getSelectedModuleSystem = id =>
  req.item(
    c.GET_SELECTED_MODULE_SYSTEM,
    `/api/v1/taxpayers/module_systems/${id}`
  );

export const resubmitTaxClearance = (id, payload, callback) =>
  req.update(
    c.RESUBMIT_TAX_CLEARANCE,
    `/api/v1/taxpayers/rptax_clearance/${id}/resubmit`,
    payload,
    callback
  );

// Select Year E2E
export const inquireYearE2E = (id, payload, callback) =>
  req.create(
    c.INQUIRE_YEAR_E2E,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/inquire_year`,
    payload,
    callback,
    {
      defaultMessage: false
    }
  );

export const selectYearE2E = (id, payload, callback) =>
  req.create(
    c.SELECT_YEAR_E2E,
    `/api/v1/bpls/taxpayer/business/permit/transactions/${id}/select_year`,
    payload,
    callback
  );

export const getBusinessInquiry = (params, callback) =>
  req.item(
    c.OHP_INQUIRE,
    "/api/v1/taxpayers/occupational_health_permits/inquire_bpls_billing",
    callback,
    params
  );

export const ovrsRegenerateSoa = (id, payload, callback) =>
  req.create(
    c.OVRS_REGENERATE_SOA,
    `/api/v1/taxpayers/ordinance_violation_receipts/${id}/regenerate_soa`,
    payload,
    callback
  );

// borongan rptax recalculate amount
export const reComputeAmount = (id, payload, callback) =>
  req.create(
    c.RPTAX_RECOMPUTE_AMOUNT,
    `/api/v1/taxpayers/real_property_taxes/billings_from_manual_imports/${id}/recompute_amount`,
    payload,
    callback
  );

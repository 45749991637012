/* global my */
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import imgPlaceHolder from "assets/img/image-placeholder.jpg";
import { connect } from "react-redux";
import { get } from "session";
import PropTypes from "prop-types";
import { handleOpenPgiUrl, scrollToTop } from "helper";
import { ModalContext } from "App";
import GCashIcon from "assets/img/gcash-white.png";
import Uploader from "modules/common/components/Uploader";
import DragAndDrop from "modules/common/components/DragAndDrop";
import Img from "modules/common/components/Img";
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import { useHistory } from "react-router-dom";
import PGINote2 from "modules/common/components/PGINote2";
import * as actions from "../actions";
import * as lookUps from "../../../constants";
import * as c from "../constants";
import { generatePGI } from "../../../actions";


const CreateMiscellaneousFees = ({
  clearItem,
  create,
  processPGI,
  PGIgenerating,
  creating,
  module,
  processGetTransactionHistoryList,
  isGCashLogin
}) => {
  const history = useHistory()
  const setModal = useContext(ModalContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    application_status_id: "",
    remarks: "",
    image_url: "",
  });

  useEffect(() => {
    scrollToTop();
  }, [isSubmitted]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setFormData({
        ...formData,
      });
      clearItem({});
    };
  }, []);

  useEffect(() => {
      setFormData({
        application_status_id: "",
        remarks: "",
        image_url: "",
      })
  }, [_.get(module, "application_status_id")]);

  const onChangeInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onCreateMiscellaneousFees = e => {
    e.preventDefault();
    setIsSubmitted(true);

    return true;
  };

  const onBack = e => {
    e.preventDefault();
    setIsSubmitted(false);
    clearItem({});
  };

  const onSubmitAndPay = e => {
    e.preventDefault();

    const source = JSON.parse(localStorage.getItem("source"));

    create(
      {
        ...formData,
        amount: _.get(module, 'amount'),
        miscellaneous_fee_id: _.get(module, "application_status_id")
      },
      (response) => {
        processGetTransactionHistoryList();

        processPGI(
          _.get(response, "data.relationships.transaction_history.data.id"),
          { source },
          (url, res) => {
            if (window.my && isGCashLogin) {
              my.postMessage({
                url: _.get(
                  res,
                  "included.gcashMiniProgramLogs.attributes.response.data.actionForm.redirectionUrl"
                )
              });
            } else {
              handleOpenPgiUrl(url);
            }

            setModal({
              isOpen: false,
            });

            setIsSubmitted(false);
            setFormData({});
            clearItem({});
            history.push("/success-page");
          }
        );
      }
    );
  };

  const payLabel = isGCashLogin ? `Pay with GCASH` : `Pay Now`;

  const handleOnUploadPhoto = key => ({ cdnUrl }) => {
    setFormData({
      ...formData,
      [key]: cdnUrl
    });
  };

  return (
    <>
      {!isSubmitted && (
        <form onSubmit={onCreateMiscellaneousFees}>
          <div className="mt-3 mb-3 pb-3 animated fadeIn">
            <div className="full-group">
              <div className="mt-3 form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Remarks{" "} (PLATE NO. / PERIOD COVERED (DATES) / MONTHLY DUES / ADDRESS)
                  </label>
                  <input
                    placeholder=""
                    disabled={_.isEmpty(_.get(module, "application_status_id"))}
                    className="full-input text-uppercase"
                    type="text"
                    autoComplete="none"
                    name="remarks"
                    id="remarks"
                    onChange={onChangeInput}
                    value={
                      _.get(formData, "remarks") || ""
                    }
                  />
                </div>
              </div>
              <div className="mt-3 form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Upload Image{" "}
                  </label>
                  <div className="mt-2 cms-bg-color-primary-ligt p-3 border-dotted" role="alert">
                    <div className="cms-text-primary ml-4">
                      Upload image of any of the following:
                    </div>
                    <ul>
                      <li>
                        Order of Payment
                      </li>
                      <li>
                        ID
                      </li>
                      <li>
                        Previous OR
                      </li>
                      <li>
                        Other Documents
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3 form-row">
                <div className="form-input-group col-12">
                  {_.isEmpty(_.get(formData, 'image_url')) ? (
                    <DragAndDrop
                      onChange={handleOnUploadPhoto("image_url")}
                      maxSize={5}
                      caption='JPEG. PNG. Maximum size 5MB.'
                      label='Upload your Requirements here'
                      fileType='image/jpeg, image/jpg, image/png'
                      isDisabled={_.isEmpty(_.get(module, "application_status_id"))}
                      isRequired
                    />
                  ) : (
                    <div className='bg-dark text-center py-2 pb-5'>
                      <div className='d-flex justify-content-end'>
                        <Uploader
                          className='btn btn-sm btn-light p-0 rounded-circle mr-2'
                          icon={<i className='fas fas fa-pen' />}
                          onChange={handleOnUploadPhoto("image_url")}
                          customLoading={<i className='fas fa-spinner fa-spin' />}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                      <Img
                        src={_.get(formData, 'image_url')}
                        className='img-fluid shadow-lg'
                        role='presentation'
                        defaultSrc={imgPlaceHolder}
                        width='70%'
                        alt=''
                      />
                    </div>
                  )}
                </div>
              </div>

            </div>
            <hr />
            <div className="form-row">
              <div className="form-input-group col-12">
                <button
                  disabled={_.isEmpty(_.get(module, "application_status_id")) || _.get(module, 'amount') === '0.00'
                    || _.isEmpty(_.get(module, 'amount'))}
                  type="submit"
                  className="btn btn-primary cms-bg-color-primary btn-md w-100"
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </form>
      )
      }
      {isSubmitted && (
        <>
          <div className="mt-3 mb-3 pb-3  animated fadeIn">
            <div className="full-group">
              <div className="mt-3 form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Remarks{" "} (PLATE NO. / PERIOD COVERED (DATES) / MONTHLY DUES / ADDRESS)
                  </label>
                  <div className="col-12 full-label text-uppercase">
                    <b className="text-uppercase">{_.get(formData, "remarks") || "---"}</b>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Uploaded Image{" "}
                  </label>
                  <div className="text-center col-12 mt-3">
                    <img src={_.get(formData, 'image_url')} alt="" className="icon-logo" />
                  </div>
                </div>
              </div>
            </div>
            {isGCashLogin &&
              <PGINote2 />}
            <hr />
            <div className="form-row">
              <div className="col-12 col-md-6 mb-1 mb-md-0 pr-md-1">
                <button
                  disabled={PGIgenerating || creating}
                  onClick={onBack}
                  type="button"
                  className="btn btn-primary cms-bg-color-primary text-white w-100"
                >
                  Back
                </button>
              </div>
              <div className="col-12 col-md-6">
                <button
                  onClick={onSubmitAndPay}
                  disabled={
                    PGIgenerating ||
                    creating
                  }
                  type="submit"
                  className={`btn btn-primary cms-bg-color-primary w-100 d-flex align-items-center ${isGCashLogin ? "gcash-button" : ""
                    }`}
                >
                  {isGCashLogin && (
                    <img src={GCashIcon} alt="GCash" width="22" height="22" />
                  )}
                  {PGIgenerating || creating ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <p className="flex-grow-1 d-flex align-items-center justify-content-center text-white m-0">
                      {payLabel}
                    </p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )
      }
    </>
  );
};

CreateMiscellaneousFees.defaultProps = {
  module: {}
}

CreateMiscellaneousFees.propTypes = {
  processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  processPGI: PropTypes.instanceOf(Function).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object),
  isGCashLogin: PropTypes.bool.isRequired
};

const mapStateToProps = ({ api }) => ({
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
  isGCashLogin: !!_.get(JSON.parse(get("profile")), "attributes.is_gcash_login")
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList
  })
]);

export default enhance(CreateMiscellaneousFees);

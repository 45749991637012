import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { connect } from 'react-redux';
import CreateMiscellaneousFees from './CreateMiscellaneousFees';


const MiscellaneousFees = ({ selectedModuleAndTransaction }) => {

  return (
    <div className="animated fadeIn">
      <CreateMiscellaneousFees module={selectedModuleAndTransaction} />
    </div>
  )
}


MiscellaneousFees.defaultProps = {
  selectedModuleAndTransaction: null
};

MiscellaneousFees.propTypes = {
  selectedModuleAndTransaction: PropTypes.instanceOf(Object)
}

const mapStateToProps = () => ({
});

const enhance = _.flowRight([
  connect(mapStateToProps)
]);

export default enhance(MiscellaneousFees);
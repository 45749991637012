import * as req from "api/actions";
import * as c from "./constants";

export const test = {};

export const searchBilling = (payload, callback) =>
  req.item(
    c.SEARCH_BILLING,
    "/api/v1/taxpayers/btax_billing_manual_imports",
    callback,
    payload
  );

export const create = (payload, callback) =>
  req.create(
    c.CREATE,
    "/api/v1/taxpayers/btax_billing_manual_imports",
    payload,
    callback
  );
export const inquireYears = (payload, callback) => 
  req.list(
    c.INQUIRE_YEARS,
    "api/v1/taxpayers/btax_billing_manual_imports/inquire_year",
    payload,
    callback
  )


export const clearItem = (data) => req.setItem(c.SEARCH_BILLING, data);

import React, { useState, useEffect, useContext, Suspense } from "react";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import _ from "lodash";
import { setItem } from "api/actions";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { get } from "session";
import Footer from "modules/common/components/Footer";
import Header from "modules/common/components/Header";
import { ModalContext } from "App";
import { Helmet } from "react-helmet";
import * as transactionAction from "modules/transaction/actions";
import Loader from "./modules/common/components/Loader";
import CmsContext from "./CmsContext";
import cmsColors from "./cmsThemes/cmsColors";
import ImportantBanner from './modal/ImportantBanner';

import MetaTagDecorator from "./MetaTagDecorator";

const ProfileView = React.lazy(() =>
  import("modules/profile/containers/SidebarProfile")
);
const GcashProfileView = React.lazy(() =>
  import("modules/profile/containers/GcashProfile")
);
const ProfileEditContainer = React.lazy(() =>
  import("modules/profile/containers/ProfileEditContainer")
);

const ProfileContainer = React.lazy(() =>
  import("modules/profile/smart-city/container/ProfileContainer")
);

const PartialPrivate = React.lazy(() =>
  import("modules/landing/containers/PartialPrivate")
);
const TransactionRoutes = React.lazy(() =>
  import("modules/transaction/containers/TransactionRoutes")
);
const About = React.lazy(() => import("modules/about/About"));
const SmartCityAbout = React.lazy(() => import("modules/about/SmartCityAbout"));
const TransactionHistoryContainer = React.lazy(() =>
  import("modules/transaction-history/containers/TransactionHistoryContainer")
);
const ApplicationHistoryContainer = React.lazy(() =>
  import("modules/transaction-history/smart-city/ApplicationHistoryRoutes")
);
const SuccessPage = React.lazy(() =>
  import("modules/common/components/SuccessPage")
);
const Faq = React.lazy(() => import("modules/faq/Faq"));
const SingleContainer2 = React.lazy(() =>
  import("modules/landing/news/SingleContainer2")
);
const VideosContainer2 = React.lazy(() =>
  import("modules/landing/news/VideosContainer2")
);
const NewsContainer2 = React.lazy(() =>
  import("modules/landing/news/NewsContainer2")
);
const NewsLanding2 = React.lazy(() =>
  import("modules/landing/news/NewsLanding2")
);
const HowTo = React.lazy(() => import("modules/how-to/containers/HowTo"));
const DownloadableForm = React.lazy(() =>
  import("modules/downloadable-form/DownloadableForm")
);
const PrivacyPolicy = React.lazy(() =>
  import("modules/privacy-policy/PrivacyPolicy")
);
const TermsAndCondition = React.lazy(() =>
  import("modules/terms-and-condition/TermsAndCondition")
);
// const ecommerce = React.lazy(() => import('modules/static/ecommerce'));
// const eservice = React.lazy(() => import('modules/static/eservice'));
const ResetPasswordContainerModal = React.lazy(() =>
  import("modules/auth/modals/ResetPasswordModal")
);
const ECommerceContainer = React.lazy(() =>
  import("modules/ecom/ECommerceContainer")
);
const EServiceContainer = React.lazy(() =>
  import("modules/eservices/eServiceContainer")
);
const ewallet = React.lazy(() => import("modules/ewallet/EWalletContainer"));
const ebills = React.lazy(() => import("modules/ebills/EBillsContainer"));
const ReGenerateBills = React.lazy(() =>
  import("modules/ebills/ReGenerateBills")
);
const WalletTopup = React.lazy(() =>
  import("modules/wallet/container/WalletTopup")
);
const PoliceClearanceNew = React.lazy(() =>
  import(
    "modules/transaction/containers/POLICE-CLEARANCE/containers/PoliceClearanceNew"
  )
);
const EhelpRoute = React.lazy(() =>
  import("modules/transaction/containers/EhelpRoute")
);
const BusinessContainer = React.lazy(() =>
  import("modules/profile/businesses/containers/BusinessContainer")
);
const GMPResetPasswordContainerModal = React.lazy(() =>
  import("modules/auth/modals/GMPResetPasswordModal")
);
const SuperAppCreatePasswordContainer = React.lazy(() =>
  import("modules/auth/containers/SuperAppCreatePasswordContainer")
);
const SSOComponent = React.lazy(() => import("modules/sso/SSOComponent"));
const SuperAppSSOComponent = React.lazy(() => import("modules/sso/SuperAppSSOComponent"));
const ComingSoon = React.lazy(() => import("modules/static/ComingSoon"));

function PrivateRoute({
  isFirstLogin,
  match,
  profile,
  isAuthenticated = "",
  isGCashLogin
}) {
  // if (_.isEmpty(profile)) return <Loader />;
  const dispatch = useDispatch();
  const cmsContext = useContext(CmsContext);
  const CMS_COLORS = cmsContext.findByType("COLORS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_ICONS = cmsContext.findByType("ICONS");

  const setModal = useContext(ModalContext);
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(true)
  const location = useLocation();
  const history = useHistory();
  const [authToken] = useState(() => isAuthenticated);
  const [validateEmail] = useState(profile.email);

  const urlParams = new URLSearchParams(window.location.search);
  const urlQueryLocation = location.search;
  useEffect(() => {   
    if(_.get(CMS_COMPONENTS, "IMPORTANT_BANNER") && location.pathname === "/") {
      setModal({
        isOpen: true,
        content: <ImportantBanner />,
        title: null,
        modalSize: "modal-md",
        hideHeader: true,
        isCentered: true
      });
      setShow(false)
    }
  }, [])

  useEffect(() => {
    if (
      urlParams.has("token") &&
      urlQueryLocation.includes(validateEmail) &&
      authToken
    ) {
      history.push(`/logout${urlQueryLocation}`);
      setModal({
        isOpen: true,
        content: <ResetPasswordContainerModal />,
        title: null,
        modalSize: "modal-lg",
        hideHeader: true,
        isCentered: true
      });
    }

    history.listen(loc => {
      if (loc.pathname !== "/e-business")
        dispatch(setItem("PREVIOUS_ROUTER", loc.pathname));
    });
  }, []);

  return (
    <>
      <MetaTagDecorator
        title={_.get(CMS_VARIABLES, "TITLE_SITE")}
        description="An electronic Real Property Taxing System that enables easy assessment and appraisal of real property taxes and allows online payments."
        imageUrl={_.get(CMS_LOGOS, "SIMPLE_LOGO.cdnUrl")}
        siteUrl={_.get(CMS_VARIABLES, "DOMAIN")}
        twitterCard={_.get(CMS_LOGOS, "SIMPLE_LOGO.cdnUrl")}
        twitterImageAlt={_.get(CMS_LOGOS, "SIMPLE_LOGO.cdnUrl")}
        siteName={_.get(CMS_VARIABLES, "TAG_LINE")}
      />
      <Helmet
        style={[
          {
            cssText: `${cmsColors(CMS_COLORS)}
              .transaction-modal .item-card:hover {
                border: 2px solid ${_.get(CMS_COLORS, "primary_color")};
              }

              .btn-to-top {
                background: url("${_.get(CMS_ICONS, "GO_UP.cdnUrl")}");
                background-repeat: no-repeat;
                background-size: cover;
              }
            `
          }
        ]}
      />
      <div className="bg-white">
        {_.get(location, "pathname") === "/auth" && (
          <Suspense fallback={<Loader />}>
            <Route path={`${match.path}auth`} component={SSOComponent} />
          </Suspense>
        )}
        {_.get(location, "pathname") === "/superapp_sso_auth" && (
          <Suspense fallback={<Loader />}>
            <Route path={`${match.path}superapp_sso_auth`} component={SuperAppSSOComponent} />
          </Suspense>
        )}
        {_.get(location, "pathname") !== "/regenerate-bills" && <Header />}
        {_.get(location, "pathname") !== "/auth" && _.get(location, "pathname") !== "/superapp_sso_auth" && (
          <div className="main-content">
            <div className="general-spacer-mobile">
              <div className="row mobile-row">
                <div className="col-12 col-md-12 mobile-row-inner">
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route
                        path={`${match.path}e-commerce`}
                        component={
                          _.get(CMS_COMPONENTS, "E_COMMERCE_COMING_SOON")
                            ? ComingSoon
                            : ECommerceContainer
                        }
                      />
                      <Route
                        path={`${match.path}e-services`}
                        component={
                          _.get(CMS_COMPONENTS, "E_SERVICES_COMING_SOON")
                            ? ComingSoon
                            : EServiceContainer
                        }
                      />
                      <Route
                        path={`${match.path}create-password`}
                        component={GMPResetPasswordContainerModal}
                      />
                      <Route
                        path={`${match.path}create-password-superapp`}
                        component={SuperAppCreatePasswordContainer}
                      />
                      <Route
                        path={`${match.path}articles/:id`}
                        component={SingleContainer2}
                      />
                      <Route
                        path={`${match.path}articles`}
                        component={NewsContainer2}
                      />
                      <Route
                        path={`${match.path}e-wallet`}
                        component={ewallet}
                      />
                      <Route
                        path={`${match.path}regenerate-bills`}
                        component={ReGenerateBills}
                      />
                      <Route
                        path={`${match.path}wallet`}
                        component={WalletTopup}
                      />
                      <Route
                        path={`${match.path}e-business`}
                        component={
                          _.get(CMS_COMPONENTS, "E_BILLS_COMING_SOON")
                            ? ComingSoon
                            : ebills
                        }
                      />
                      <Route
                        path={`${match.path}videos`}
                        component={VideosContainer2}
                      />
                      <Route
                        path={`${match.path}news`}
                        component={NewsLanding2}
                      />
                      <Route
                        path={`${match.path}success-page`}
                        component={SuccessPage}
                      />
                      <Route
                        path={`${match.path}privacy-policy`}
                        render={() => (
                          <PartialPrivate title="Privacy Policy" description="">
                            <PrivacyPolicy />
                          </PartialPrivate>
                        )}
                      />
                      <Route
                        path={`${match.path}terms-and-condition`}
                        render={() => (
                          <PartialPrivate
                            title="Terms and Condition"
                            description=""
                          >
                            <TermsAndCondition />
                          </PartialPrivate>
                        )}
                      />
                      <Route
                        path={`${match.path}about`}
                        render={() => (
                          <PartialPrivate title="About" description="">
                            {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) ? 
                              <About /> :
                              <SmartCityAbout />
                            }
                            
                          </PartialPrivate>
                        )}
                      />
                      <Route
                        path={`${match.path}faq`}
                        render={() => (
                          <PartialPrivate
                            title="Frequently Asked Questions"
                            description=""
                          >
                            <Faq />
                          </PartialPrivate>
                        )}
                      />
                      <Route
                        path={`${match.path}how-to`}
                        render={props => (
                          <PartialPrivate
                            title="How Tos"
                            description="How To Use"
                          >
                            <HowTo match={props.match} />
                          </PartialPrivate>
                        )}
                      />
                      <Route
                        path={`${match.path}create-transaction`}
                        component={TransactionRoutes}
                      />
                      <Route
                        path={`${match.path}e-help`}
                        render={props => (
                          <EhelpRoute match={props.match} isPrivate />
                        )}
                      />
                      <Route
                        path={`${match.path}downloadable-form`}
                        render={() => (
                          <PartialPrivate
                            title="Downloadable Forms"
                            description=""
                          >
                            <DownloadableForm />
                          </PartialPrivate>
                        )}
                      />

                      <Route
                        path={`${match.path}transaction-history`}
                        component={
                          (_.get(CMS_COMPONENTS, "SMART_CITY_TRANSACTION_HISTORY") === false || _.get(CMS_COMPONENTS, "SMART_CITY_TRANSACTION_HISTORY") === undefined)
                            ? TransactionHistoryContainer
                            : ApplicationHistoryContainer
                        }
                      />
                      <Route
                        path={`${match.path}profile-edit`}
                        component={
                          (_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined)
                            ? ProfileEditContainer
                            : ProfileContainer
                        }
                      />
                      <Route
                        path={`${match.path}businesses`}
                        component={BusinessContainer}
                      />
                      <Route
                        path={`${match.path}pc`}
                        component={PoliceClearanceNew}
                      />
                      <Route
                        path={`${match.path}`}
                        component={
                          !isGCashLogin ? ProfileView : GcashProfileView
                        }
                      />
                    </Switch>
                  </Suspense>
                </div>
                <Route
                  path={`${match.path}`}
                  render={() =>
                    isFirstLogin ? (
                      <Redirect to={`${match.path}profile-edit`} />
                    ) : null
                  }
                />
              </div>
            </div>
          </div>
        )}
        <Footer />

        {/* <Route path={`${match.path}logout`} component={LogoutContainer} /> */}
      </div>
    </>
  );
}

PrivateRoute.propTypes = {
  isFirstLogin: PropTypes.bool.isRequired,
  isGCashLogin: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = () => ({
  isFirstLogin: !!_.get(
    JSON.parse(get("profile")),
    "attributes.is_first_login"
  ),
  isGCashLogin: !!_.get(
    JSON.parse(get("profile")),
    "attributes.is_gcash_login"
  ),
  profile: _.get(JSON.parse(get("profile")), "attributes") || {}
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, transactionAction)
]);

export default enhance(PrivateRoute);

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

const modalRoot = document.getElementById('modal-root');
export const Body = ({ children, className }) => (
  <div className={`modal-body ${className}`}>{children}</div>
);

Body.defaultProps = {
  children: '',
  className: '',
};

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.instanceOf(Array)]),
  className: PropTypes.string,
};

export const Footer = ({ children, className }) => (
  <div className={`modal-footer ${className}`}>{children}</div>
);

Footer.defaultProps = {
  children: '',
  className: '',
};

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.instanceOf(Array)]),
  className: PropTypes.string,
};

class Modal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.instanceOf(Function),
    data: PropTypes.instanceOf(Object),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    // cmsContext: PropTypes.instanceOf(Function),
  };

  static defaultProps = {
    children: null,
    onClose: null,
    data: {
      hideHeader: false
    },
    // cmsContext: null
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.handleShortcut);
    this.detectIfModalIsOpen();
    modalRoot.appendChild(this.el);
  }

  componentDidUpdate() {
    this.detectIfModalIsOpen();
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleShortcut);
    modalRoot.removeChild(this.el);
  }

  detectIfModalIsOpen = () => {
    const { data } = this.props;
    if (_.get(data, 'isOpen')) {
      document.body.classList.add('modal-open');
      return;
    }
    document.getElementsByTagName('body')[0].removeAttribute('class');
  };

  handleShortcut = (e) => {
    if (e.key === 'Escape' && !document.getElementById('prevent-modal-close')) {
      const { onClose } = this.props;
      onClose();
    }
  };
  
  render() {
    const { onClose, children, data } = this.props;
    
    return ReactDOM.createPortal(
      <div id="main-modal">
        <div
          key="modal"
          className={`modal ${_.get(data, 'isOpen') ? 'show' : ''}`}
          style={{
            display: _.get(data, 'isOpen') ? 'block' : 'none',
          }}
          tabIndex={-1}
        >
          <div
            className={`modal-dialog ${_.get(data, 'modalSize')} ${_.get(data, 'isCentered')
              && 'modal-dialog-centered'}`}
          >
            <div className="modal-content animated fadeIn faster">
              {!_.get(data, 'hideHeader') && (
                <div className="modal-header d-flex justify-content-between">
                  <h4 className="modal-title font-weight-bold">
                    {_.get(data, 'isOpen') ? _.get(data, 'title') : null}
                  </h4>
                  {!_.get(data, 'noCloseButton') && (
                    <button type="button" className="close" onClick={onClose}>
                      <span>&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                  )}
                </div>
              )}
              {_.get(data, 'isOpen') ? children : null}
            </div>
          </div>
        </div>
        <div
          key="modal-backdrop"
          className={`modal-backdrop ${_.get(data, 'isOpen') ? 'show' : ''}`}
          style={{
            display: _.get(data, 'isOpen') ? 'block' : 'none',
          }}
        />
      </div>,
      this.el
    );
  }
}

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;

import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { removeItem } from "session";
import { withRouter } from "react-router-dom";
import { handleOpenPgiUrl, reactSelectCustomStyle } from "helper";
import { VALID_IDS } from "constant";
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import MiniLoader from "modules/common/components/MiniLoader";
import useFormValidator from "useFormValidator";
import { ModalContext } from "App";
import PropertyInfo from "./PropertyInfo";
import AdvanceList from "./AdvanceList";
import DelinquentList from "./DelinquentList";
import OnProcessList from "./OnProcessList";
import * as c from "../../constants";
import * as actions from "../../actions";
import { generatePGI } from "../../../../actions";
import * as lookUps from "../../../../constants";
import CmsContext from "../../../../../../CmsContext";

function PropertyList({
  owner_id,
  year,
  properties,
  pending,
  calculate,
  calculating,
  processPGI,
  history,
  processGetTransactionHistoryList,
  create,
  creating,
  PGIgenerating,
  module,
  delinquent,
  advance,
}) {
  const cmsContext = useContext(CmsContext)
  const CMS_VARIABLES = cmsContext.findByType('VARIABLES');
  const modList = (data) => {
    const modifiedList = [];
    data.map((x) => {
      return modifiedList.push({
        ...x,
        selected: 0,
        period: 4,
      });
    });
    return modifiedList;
  };
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({});
  const [list, setList] = useState(modList(properties));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  // const [checkAll, setCheckAll] = useState(false);
  const [propDetail, setPropDetail] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');

  const onChangeSelect = (name, index) => (e) => {
    setFormData({
      ...formData,
      [name]: e.value,
    });

    setIsCalculated(false);

    const cList = [...list];
    cList[index].period = e.value;
    setList(cList);
  };

  const onChangeID = (name) => (e) => {
    setFormData({
      ...formData,
      [name]: e.value,
    });
  };

  const validateValidId = useFormValidator(formData, {
    valid_id_to_present: "required",
  });

  useEffect(() => {
    validateValidId.validate();
  }, [Object.values(formData).join(",")]);

  const handleCalculate = dataCalculated => (e) => {
    e.preventDefault();
    // const selected = _.filter(list, ["selected", true]);
    const dataJson = {
      ...dataCalculated,
      selected: true,
    };

    let propertyList = [];
    propertyList = [...propertyList, dataJson];
    const args = {
      _method: "put",
      owner_id,
      year,
      properties: propertyList,
    };
    calculate(args, (data) => {
      setIsCalculated(true);
      setList(_.get(data, "res.data.data.properties"));
      setTotalAmount(_.get(data, "res.data.data.total"));
    });
  };

  // const onChangeInput = (index) => (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.checked,
  //   });
  //   const cList = [...list];
  //   cList[index].selected = e.target.checked;
  //   setList(cList);
  //   setIsCalculated(false);
  // };

  const renderPropDetail = (prop) => {
    const selected = _.filter(prop, ["selected", true]);
    if (selected) {
      return selected ? selected.map((d) => <PropertyInfo data={d} totalAmount={totalAmount} />) : "";
    }
    return <span>None</span>;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPropDetail(list);
    setIsSubmitted(true);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const args = {
      owner_id,
      year,
      valid_id_to_present: _.get(formData, "valid_id_to_present"),
      application_status_id: _.get(module, "application_status_id"),
      properties: _.filter(list, ["selected", true]),
    };
    ConfirmAlert(
      <div>
        <p className="mb-0 text-danger">IMPORTANT!</p>
        <p className="font-weight-bold "> To ensure verification of bank payments made,
          Please upload your proof of payments by going to your transaction history and clicking the
          <b> Upload your payment receipt</b> button or send your proof of payments to <b>support@multipay.ph</b>
        </p>
      </div>,
      () => {
        create(args, (response) => {
          processGetTransactionHistoryList();
          processPGI(
            _.get(response, "data.relationships.transaction_history.data.id"),
            (url) => {
              setModal({
                isOpen: false,
              });

              handleOpenPgiUrl(url);
              history.push("/success-page");
              removeItem("application-info");
            }
          );
        });
      });
  };

  // const handleOnSelectAll = (e) => {
  //   if (e.target.checked) {
  //     setCheckAll(true);
  //     // setFormData({
  //     //   ...formData,
  //     //   [e.target.name]: e.target.checked,
  //     // });
  //     const cList = [...list];
  //     // cList[index].selected = e.target.checked;
  //     const options =
  //       cList.map(
  //         (item) => {
  //           const data = {
  //             ...item,
  //             selected: e.target.checked,
  //           };
  //           return data;
  //         }
  //       ) || [];
  //     setList(options);
  //     setIsCalculated(false);
  //   } else {
  //     setCheckAll(false);
  //     // setFormData({
  //     //   ...formData,
  //     //   [e.target.name]: e.target.checked,
  //     // });
  //     const cList = [...list];
  //     // cList[index].selected = e.target.checked;
  //     const options =
  //       cList.map(
  //         (item) => {
  //           const data = {
  //             ...item,
  //             selected: e.target.checked,
  //           };
  //           return data;
  //         }
  //       ) || [];
  //     setList(options);
  //     setIsCalculated(false);
  //   }
  // };

  const onBack = (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    setIsCalculated(false);
    setPropDetail([]);
    const backToList = [];
    properties.map((x) => {
      return backToList.push({
        ...x,
        selected: 0,
        period: 4,
      });
    });
    setList(backToList);
  };

  const renderPropertyList = () => {
    return (
      <>
        <div className='col-md-12 d-flex text-center justify-content-center mt-3 t-border'>
          <div className='alert alert-info w-100' role='alert'>
            Ready for Payment
          </div>
        </div>
        <div className="mt-2">
          {list.map((d, i) => (
            <div key={`${d.owner_id}${d.pin}`} className="card mb-3">
              <div className="row card-body">
                <div className="col-12 col-md-4"><b>ARP No.</b></div>
                <div className="col-12 col-md-8">{d.new_arp || '-'}</div>
                <div className="col-12 col-md-4"><b>PIN</b></div>
                <div className="col-12 col-md-8">{d.new_pin || '-'}</div>
                <div className="col-12 col-md-4"><b>Brgy.</b></div>
                <div className="col-12 col-md-8">{d.barangay || '-'}</div>
                <div className="col-12 col-md-4"><b>Survey No.</b></div>
                <div className="col-12 col-md-8">{d.survey_no || '-'}</div>
                <div className="col-12 col-md-4"><b>Tax Year</b></div>
                <div className="col-12 col-md-8">{d.tax_year || '-'}</div>
                <div className="col-12 col-md-4"><b>Tax Due</b></div>
                <div className="col-12 col-md-8">{d.tax_due_human ? d.tax_due_human : d.initial_amount}</div>
                <div className="col-12 col-md-4"><b>Penalty</b></div>
                <div className="col-12 col-md-8">{d.penalty_human || '-'}</div>
                <div className="col-12 col-md-4"><b>Discount</b></div>
                <div className="col-12 col-md-8">{d.discount_human || '-'}</div>
                <div className="col-12 col-md-4"><span className="font-weight-bold text-danger">Total Amount</span></div>
                <div className="col-12 col-md-8"><b>{d.total_human || '-'}</b></div>
                {year === +moment().format("YYYY") && (
                  <>
                    <div className="col-12 col-md-4"><b>Period</b></div>
                    <div className="col-12 col-md-8">
                      <Select
                        size="5"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="select-css"
                        required
                        name={`pselect-${i}`}
                        options={c.periodOptions}
                        onChange={onChangeSelect(`pselect-${i}`, i)}
                        value={
                          c.periodOptions.find(
                            (item) =>
                              _.get(item, "value") === _.get(list, `${i}.period`)
                          ) || ""
                        }
                      />
                    </div>
                  </>
                )}
                <div className='col mt-4 text-center'>
                  <button
                    disabled={
                      calculating
                    }
                    onClick={handleCalculate(d)}
                    type='button'
                    className='btn btn-danger mb-2 w-100'
                  >
                    <span className="text-white">Calculate</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <div className='form-wrapper mb-3 pb-3  animated fadeIn'>
        <div className='full-group '>
          <div className='form-row'>
            <div className='form-input-group col-12'>
              <label htmlFor='last_name' className='full-label mb-0'>
                Owner ID
              </label>
              <input value={owner_id} className='full-input' readOnly />
            </div>
          </div>
          {advance &&
            !_.isEmpty(advance) &&
            _.isEmpty(propDetail) &&
            !calculating && (
              <div className='form-row'>
                <AdvanceList data={advance} />
              </div>
            )}
          {delinquent &&
            !_.isEmpty(delinquent) &&
            _.isEmpty(propDetail) &&
            !calculating && (
              <div className='form-row'>
                <DelinquentList data={delinquent} />
              </div>
            )}
          {pending &&
            !_.isEmpty(pending) &&
            _.isEmpty(propDetail) &&
            !calculating && (
              <div className='form-row'>
                <OnProcessList data={pending} />
              </div>
            )}

          {_.isEmpty(propDetail) &&
            !calculating &&
            properties &&
            !_.isEmpty(properties) && (
              <div className='form-row'>{renderPropertyList(properties)}</div>
            )}
          {!_.isEmpty(propDetail) && !calculating && (
            <>
              <div className='form-row'>{renderPropDetail(propDetail)}</div>
              <br />
              <div className='d-flex'>
                <div className='property-title'>
                  Total Amount
                </div>
                <div className='property-punc ml-2 mr-2'>
                  :
                </div>
                <div className='property-value'>
                  <span className='full-label text-uppercase text-right'>
                    {'\u20B1'}{totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                </div>
              </div>
            </>
          )}
          {calculating && <MiniLoader />}
        </div>
      </div>
      {/* <div className='full-group mb-5 text-center'>
        {_.isEmpty(propDetail) &&
          !calculating &&
          properties &&
          !_.isEmpty(properties) && (
            <button
              disabled={
                _.isEmpty(_.filter(list, ["selected", true])) || calculating
              }
              onClick={handleCalculate}
              type='button'
              className='btn btn-warning btn-sm mb-2'>
              Calculate
            </button>
          )}
      </div> */}
      {isSubmitted && (
        <>
          <div className='full-group '>
            <div className='form-row'>
              <div className='form-input-group col-12'>
                <label htmlFor='last_name' className='full-label mb-0'>
                  Valid ID to Present Upon Claiming Official Receipt (O.R){" "}
                  {validateValidId.renderMessage("valid_id_to_present")}
                </label>
                <Select
                  styles={reactSelectCustomStyle}
                  placeholder='Select'
                  value={
                    VALID_IDS.find(
                      (item) =>
                        _.get(formData, "valid_id_to_present") ===
                        _.get(item, "value")
                    ) || ""
                  }
                  onChange={onChangeID("valid_id_to_present")}
                  options={VALID_IDS}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
          {
            _.get(CMS_VARIABLES, 'LGU_NAME').includes("angeles") ?
              <div className='alert alert-primary text-center mt-3' role='alert'>
                Note: An additional convenience fee will be charged to use this
                e-payment service, and once a reference number is generated, please
                pay with in the day.
              </div> :
              <div className='alert alert-primary text-center mt-3' role='alert'>
                <span className="text-left font-weight-bold">
                  #1. Issuance of Official Receipts can be claimed at
                  the Angeles City Treasurer&#39;s Office, 7 Business Days after this payment is made.
                </span>
                <br />
                <br />
                Note: An additional convenience fee will be charged to use this
                e-payment service, and once a reference number is generated, please
                pay with in the day.
              </div>
          }
        </>
      )}
      <div className='full-group'>
        <>
          {!isSubmitted ? (
            <button
              disabled={!isCalculated || calculating}
              onClick={handleSubmit}
              type='button'
              className={`btn btn-info btn-sm w-100 mb-2 rpt-submit ${!isCalculated || calculating ? "rpt-disabled" : ""
                }`}>
              Submit
            </button>
          ) : (
            <>
              <div className='full-group'>
                <button
                  onClick={onBack}
                  type='button'
                  className='btn btn-primary btn-sm w-100 mb-2'>
                  Back to Calculate
                </button>
              </div>
              <button
                onClick={handleConfirm}
                disabled={
                  PGIgenerating || creating || !validateValidId.response.is_passed
                }
                type='submit'
                className='btn btn-info btn-sm w-100 mb-2'>
                {PGIgenerating || creating ? (
                  <i className='fa fa-spinner fa-spin' />
                ) : (
                  `Pay Now`
                )}
              </button>
            </>
          )}
        </>
      </div>
    </>
  );
}

PropertyList.propTypes = {
  processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  owner_id: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  calculating: PropTypes.bool.isRequired,
  properties: PropTypes.instanceOf(Array).isRequired,
  delinquent: PropTypes.instanceOf(Array).isRequired,
  pending: PropTypes.instanceOf(Array).isRequired,
  advance: PropTypes.instanceOf(Array).isRequired,
  calculate: PropTypes.instanceOf(Function).isRequired,
  processPGI: PropTypes.instanceOf(Function).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  calculating: api.loading.indexOf(c.CALCULATE) > -1,
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList,
  }),
]);

export default enhance(PropertyList);
/* global my */
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { get } from "session";
import PropTypes from "prop-types";
import moment from "moment";
import EmptyState from "modules/common/components/EmptyState";
import Select from "react-select";
import { handleOpenPgiUrl, scrollToTop } from "helper";
// import InputMask from "react-input-mask";
import useFormValidator from "useFormValidator";
import { ModalContext } from "App";
import GCashIcon from "assets/img/gcash-white.png";
import { VALID_IDS } from "constant";
// import PaymentGatewayIframeModalContent from 'modules/common/components/PaymentGatewayIframeModalContent';
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import * as actions from "../actions";
import * as lookUps from "../../../constants";
import * as c from "../constants";
import { generatePGI } from "../../../actions";

// const years = _.range(1970, +moment().format("YYYY") + 1).sort((a, b) => b - a);

// const yearOptions = years.map((year) => ({
//   label: year,
//   value: year,
// }));

// const perioOptions = [
//   {
//     label: "1st Quarter",
//     value: 1,
//   },
//   {
//     label: "2nd Quarter",
//     value: 2,
//   },
//   {
//     label: "3rd Quarter",
//     value: 3,
//   },
//   {
//     label: "4th Quarter",
//     value: 4,
//   },
// ];

const SearchBilling = ({
  searchBilling,
  searching,
  billingData,
  clearItem,
  create,
  processPGI,
  PGIgenerating,
  creating,
  module,
  processGetTransactionHistoryList,
  isGCashLogin
}) => {
  const setModal = useContext(ModalContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    pin: "",
    nov_no: "",
    valid_id_to_present: ""
  });

  const validateForm = useFormValidator(formData, {
    pin: "required",
    nov_no: "required"
  });

  const validateValidId = useFormValidator(formData, {
    valid_id_to_present: "required"
  });

  useEffect(() => {
    scrollToTop();
  }, [isSubmitted]);

  useEffect(() => {
    validateForm.validate();
    validateValidId.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setFormData({});
      clearItem({});
    };
  }, []);

  const onChangeInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onChangeSelect = name => e => {
    setFormData({
      ...formData,
      [name]: e.value
    });
  };

  const onSearchBilling = e => {
    e.preventDefault();
    if (!validateForm.response.is_passed) {
      return false;
    }
    searchBilling(formData, () => {
      setIsSubmitted(true);
    });

    return true;
  };

  // const formatPeriod = (data) => {
  //   return data
  //     ? data
  //         .replace("-1", " - 1st quarter")
  //         .replace("-2", " - 2nd quarter")
  //         .replace("-3", " - 3rd quarter")
  //         .replace("-4", " - 4th quarter")
  //     : "---";
  // };

  const onBack = e => {
    e.preventDefault();
    setIsSubmitted(false);
    clearItem({});
  };

  const onSubmitAndPay = e => {
    e.preventDefault();
    create(
      {
        ...formData,
        application_status_id: _.get(module, "application_status_id")
      },
      response => {
        processGetTransactionHistoryList();
        processPGI(
          _.get(response, "data.relationships.transaction_history.data.id"),
          (url, res) => {
            if (window.my && isGCashLogin) {
              my.postMessage({
                url: _.get(
                  res,
                  "included.gcashMiniProgramLogs.attributes.response.data.actionForm.redirectionUrl"
                )
              });
            } else {
              handleOpenPgiUrl(url);
            }

            setModal({
              isOpen: false
            });

            setIsSubmitted(false);
            setFormData({});
            clearItem({});
          }
        );
      }
    );
  };

  const payLabel = isGCashLogin ? `Pay with GCASH` : `Pay Now`;

  return (
    <>
      {!isSubmitted && _.isEmpty(billingData) && (
        <form onSubmit={onSearchBilling}>
          <div className="form-wrapper mb-3 pb-3  animated fadeIn">
            <div className="full-group ">
              <div className="form-row">
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    Notice of Violation Number (NOV){" "}
                    {validateForm.renderMessage("nov_no")}
                  </label>
                  <input
                    placeholder=""
                    className="full-input text-uppercase"
                    // maskChar=' '
                    mask="999-99-9999-9999999"
                    autoComplete="none"
                    id="nov_no"
                    name="nov_no"
                    onChange={onChangeInput}
                    value={_.get(formData, "nov_no") || ""}
                  />
                </div>
                <div className="form-input-group col-12">
                  <label htmlFor="last_name" className="full-label mb-0">
                    PIN {validateForm.renderMessage("pin")}
                  </label>
                  <input
                    placeholder=""
                    className="full-input text-uppercase"
                    // maskChar=' '
                    mask="999-99-9999-9999999"
                    autoComplete="none"
                    id="pin"
                    name="pin"
                    onChange={onChangeInput}
                    value={_.get(formData, "pin") || ""}
                  />
                </div>
                {/* <div className='form-input-group col-12'>
                  <label htmlFor='last_name' className='full-label mb-0'>
                    Year {validateForm.renderMessage("year")}
                  </label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={yearOptions}
                    styles={reactSelectCustomStyle}
                    onChange={onChangeSelect("year")}
                    value={
                      yearOptions.find(
                        (item) =>
                          _.get(item, "value") === _.get(formData, "year")
                      ) || ""
                    }
                  />
                </div>
                <div className='form-input-group col-12'>
                  <label htmlFor='last_name' className='full-label mb-0'>
                    Period {validateForm.renderMessage("period")}
                  </label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={perioOptions}
                    styles={reactSelectCustomStyle}
                    onChange={onChangeSelect("period")}
                    value={
                      perioOptions.find(
                        (item) =>
                          _.get(item, "value") === _.get(formData, "period")
                      ) || ""
                    }
                  /> 
                </div> */}
              </div>
            </div>
            <div className="full-group pt-3">
              <button
                disabled={searching || !validateForm.response.is_passed}
                type="submit"
                className="btn btn-primary cms-bg-color-primary btn-lg w-100"
              >
                {searching ? <i className="fa fa-spinner fa-spin" /> : `Submit`}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmitted && _.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <EmptyState message="No Record Found" />
          <div className="full-group">
            <button
              onClick={onBack}
              type="button"
              className="btn btn-primary cms-bg-color-primary btn-lg w-100"
            >
              Back
            </button>
          </div>
        </>
      )}
      {isSubmitted && !_.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <div className="card  animated fadeIn">
            <div className="row">
              <div className="col-12 text-center cms-text-primary">
                Notice of Violation number (NOV)
              </div>
              <div className="col-12 text-center cms-text-primary">
                <b>{_.get(billingData, "nov_no") || "---"}</b>
              </div>
            </div>
          </div>
          <br />
          <table className="table tabl-sm table-striped table-sm mb-0 border-bottom">
            <tbody>
              <tr>
                <td style={{ width: 150 }}>PLATE NUMBER</td>
                <td>:</td>
                <td>
                  <b>{_.get(billingData, "plate_no") || "---"}</b>
                </td>
              </tr>
              <tr>
                <td>AMOUNT</td>
                <td>:</td>
                <td>
                  <b>
                    {_.get(billingData, "total_amount")
                      ? parseFloat(
                          _.get(billingData, "total_amount")
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      : "0.00"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>DATE</td>
                <td>:</td>
                <td>
                  <b>
                    {_.get(billingData, "created_at")
                      ? moment(`${_.get(billingData, "created_at")}`).format(
                          "MMM DD, YYYY HH:mm:ss A"
                        )
                      : "---"}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <b className="text-primary cms-text-primary">Violations</b>
          <div className="d-flex flex-row">
            {_.get(billingData, "violations").map(violation => {
              return (
                <div className="card p-2 my-2">
                  <table className="table table-sm mb-0 table-borderless">
                    <tbody>
                      <tr>
                        <td style={{ width: 150 }}>VIOLATION</td>
                        <td>:</td>
                        <td>{_.get(violation, "violation_name") || "---"}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 150 }}>ARTICLE</td>
                        <td>:</td>
                        <td>
                          {_.get(violation, "violation_article") || "---"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: 150 }}>AMOUNT</td>
                        <td>:</td>
                        <td>
                          {_.get(violation, "violation_amount")
                            ? parseFloat(
                                _.get(violation, "violation_amount")
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            : "0.00"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="full-group ">
            <div className="form-row">
              <div className="form-input-group col-12">
                <label htmlFor="last_name" className="full-label mb-0">
                  Valid ID to Present Upon Claiming{" "}
                  {validateValidId.renderMessage("valid_id_to_present")}
                </label>
                <Select
                  placeholder="Select"
                  value={
                    VALID_IDS.find(
                      item =>
                        _.get(formData, "valid_id_to_present") ===
                        _.get(item, "value")
                    ) || ""
                  }
                  onChange={onChangeSelect("valid_id_to_present")}
                  options={VALID_IDS}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
            </div>
          </div>
          <div className="alert alert-primary text-center mt-3" role="alert">
            Note: An additional convenience fee will be charged to use this
            e-payment service, and once a reference number is generated, please
            pay within the day.
          </div>
          <hr />
          <div className="row">
            <div className="col-12 col-md-6 mb-1 mb-md-0 pr-md-1">
              <button
                disabled={PGIgenerating || creating}
                onClick={onBack}
                type="button"
                className="btn btn-primary cms-bg-color-primary btn-lg w-100"
              >
                Back
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button
                onClick={onSubmitAndPay}
                disabled={
                  PGIgenerating ||
                  creating ||
                  !validateValidId.response.is_passed
                }
                type="submit"
                className={`btn btn-success btn-lg w-100 d-flex align-items-center ${
                  isGCashLogin ? "gcash-button" : ""
                }`}
              >
                {isGCashLogin && (
                  <img src={GCashIcon} alt="GCash" width="22" height="22" />
                )}
                {PGIgenerating || creating ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <p className="flex-grow-1 d-flex align-items-center justify-content-center m-0">
                    {payLabel}
                  </p>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

SearchBilling.propTypes = {
  processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  searchBilling: PropTypes.instanceOf(Function).isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  searching: PropTypes.bool.isRequired,
  billingData: PropTypes.instanceOf(Object).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  processPGI: PropTypes.instanceOf(Function).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  isGCashLogin: PropTypes.bool.isRequired
};

const mapStateToProps = ({ api }) => ({
  searching: api.loading.indexOf(c.SEARCH_BILLING) > -1,
  billingData: _.get(api, `${c.SEARCH_BILLING}.item`) || {},
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
  isGCashLogin: !!_.get(JSON.parse(get("profile")), "attributes.is_gcash_login")
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList
  })
]);

export default enhance(SearchBilling);

import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { isNumber } from "helper";
import "react-phone-input-2/lib/bootstrap.css";
import uuid from "react-uuid";

function FormInputMobile({
  name,
  onChange,
  label,
  disableDropdown,
  value,
  countryCodeEditable,
  hasCountry,
  ...props
}) {
  const [unique_id] = React.useState(uuid());
  const handleChangeHasCountry = (val) => {
    onChange((state) => ({ ...state, [name]: val }));
  };

  const handleChange = ({ target }) => {
    if (isNumber(target.value)) {
      onChange((state) => ({ ...state, [name]: target.value }));
    }
  };

  return (
    <div className='form-group'>
      
      {/* <label htmlFor={`${unique_id}-${name}`} className='form-label'>
        {label}
      </label> */}
      {hasCountry ? (
        <div className='form-icon-right'>
          <PhoneInput
            onlyCountries={['ph']}
            country='ph'
            enableSearch={false}
            value={value}
            searchPlaceholder='Search Country'
            jumpCursorToEnd
            disableDropdown={disableDropdown}
            className='form-custom form-custom'
            placeholder='+639 XXXX XXXXX'
            onChange={handleChangeHasCountry}
            countryCodeEditable={countryCodeEditable}
            masks={{ ph: "...-...-...." }}
            priority={{ ph: 0 }}
            autocompleteSearch
            // disableCountryCode
            inputProps={{
              id: `${unique_id}-${name}`,
              type: "tel",
              autoComplete: "off",
              // pattern: ".{10,}",
              ...props,
            }}
            searchClass='d-flex w-100'
            searchStyle={{
              width: "80%",
            }}
          />
        </div>
      ) : (
        <div className='form-icon-text'>
          <span className='left'>+63</span>
          <input
            style={{ width: "100%" }}
            type='tel'
            id={`${unique_id}-${name}`}
            className='form-control form-custom'
            onChange={handleChange}
            value={value}
            placeholder='9XXXXXXXXX'
            maxLength='10'
            // pattern='.{10,}'
            {...props}
          />
        </div>
      )}
    </div>
  );
}

FormInputMobile.defaultProps = {
  onChange: () => {},
  label: "Enter Mobile Number",
  disableDropdown: true,
  countryCodeEditable: false,
  hasCountry: true,
};

FormInputMobile.propTypes = {
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  disableDropdown: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  hasCountry: PropTypes.bool,
};

export default React.memo(FormInputMobile);

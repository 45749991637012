import * as req from "api/actions";
import * as c from "./constants";

export const test = {};

export const searchBilling = (payload, callback) =>
  req.item(
    c.SEARCH_BILLING,
    "/api/v1/taxpayers/btax_billing_csv_imports",
    callback,
    payload
  );

export const create = (payload, callback) =>
  req.create(
    c.CREATE,
    "/api/v1/taxpayers/business_taxes/billings_from_csv_imports",
    payload,
    callback
  );

export const clearItem = (data) => req.setItem(c.SEARCH_BILLING, data);

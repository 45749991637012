/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

function Img({ src, defaultSrc, lightBox, ...rest }) {
  const [url, setUrl] = React.useState(src || defaultSrc);
  // const [error, setError] = React.useState(false);
  // const handleError = () => {
  //   setError(`image error! ${new Date().getTime()}`);
  //   setUrl(defaultSrc);
  // };
  React.useEffect(() => {
    setUrl(src || defaultSrc);
  }, [src]); // eslint-disable-line
  return (
    <>
      {/* <div>{error}</div> */}
      <img
        alt='thumb'
        // onError={handleError}
        role='presentation'
        style={{ cursor: lightBox ? "pointer" : "initial" }}
        {...rest}
        src={url}
      />
    </>
  );
}

Img.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  lightBox: PropTypes.bool,
};

Img.defaultProps = {
  src: "",
  defaultSrc: "https://via.placeholder.com/128?text=IMG",
  lightBox: false,
};

export default Img;

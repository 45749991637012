import * as c from "./constants";

export const startLoading = (x, z = "loader") => ({
  ...x,
  loading: x.loading.concat([z]),
});

export const endLoading = (x, z = "loader") => ({
  ...x,
  loading: x.loading.filter((y) => y !== z),
});

export const apiInitState = {
  loading: [],
};

export default {
  [c.GET_LIST]: (state, { key }) => startLoading(state, key),
  [c.GOT_LIST]: (state, { key, data, pager, res }) =>
    endLoading(
      {
        ...state,
        [key]: {
          list: data || [],
          pager: pager || {},
          res: res || {},
        },
      },
      key
    ),
  [c.GET_ITEM]: (state, { key }) => startLoading(state, key),
  [c.FIND_POST]: (state, { key }) => startLoading(state, key),
  [c.FOUND_POST]: (state, { key }) => endLoading(state, key),
  [c.GOT_ITEM]: (state, { key, data, res }) => {
    if (key === "AUTH/logout") return apiInitState;
    return endLoading(
      {
        ...state,
        [key]: {
          item: data || [],
          res: res || {},
        },
      },
      key
    );
  },
  [c.CREATE_ITEM]: (state, { key }) => startLoading(state, key),
  [c.CREATED_ITEM]: (state, { key }) => endLoading(state, key),
  [c.UPDATE_ITEM]: (state, { key }) => startLoading(state, key),
  [c.UPDATED_ITEM]: (state, { key }) => endLoading(state, key),
  [c.UPLOAD_FORM_DATA]: (state, { key }) => startLoading(state, key),
  [c.UPLOADED_FORM_DATA]: (state, { key }) => endLoading(state, key),
  [c.DOWNLOAD]: (state, { key }) => startLoading(state, key),
  [c.DOWNLOADED]: (state, { key }) => endLoading(state, key),

  [c.GOT_ITEM_DELAY]: (state, { key }) => startLoading(state, key),

  ON_ERROR: (state, { key }) => ({
    ...state,
    loading: state.loading.filter((x) => x !== key),
  }),
};

import React, { useEffect, useContext } from "react";
import { withRouter, Switch, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Alert from "react-s-alert";
import _ from "lodash";
import { removeItem } from "session"
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga';
import AlertTemplate from "modules/common/components/AlertTemplate";
import Marker from "modules/common/components/Alert";
import Modal from "modules/common/components/Modal";
import withAuth from "modules/auth/hoc/withAuth";
import ServiceUnavailable from "modules/error-pages/serviceUnavailable";
import PrivateRoutes from "PrivateRoutes";
import * as commonActions from "modules/common/actions";
// import Loader from "modules/common/components/Loader";
import PublicRoutes from "./PublicRoutes";
import PGIPage from "./PGIPage";
import CmsContext from "./CmsContext";

export const ModalContext = React.createContext(() => { });

const HeaderComponent = () => {
  const cmsContext = useContext(CmsContext)
  const CMS_FAVICON = cmsContext.findByType('LOGOS');
  const CMS_VARIABLES = cmsContext.findByType('VARIABLES');

  return (
    <Helmet defer={false}>
      <link
        id="favicon"
        rel="icon"
        href={`${_.get(CMS_FAVICON, 'FAVICON.cdnUrl')}`}
        type="image/x-icon"
      />
      <title>{_.get(CMS_VARIABLES, "TITLE_SITE")}</title>
    </Helmet>
  )
}

function App({ auth, modal, setModal, match, getCms, cmsList, isLoadingCms }) {
  const cmsContext = useContext(CmsContext)
  const history = useHistory()

  const isAuthenticated = _.get(auth, "item.isAuthenticated");
  const onCloseModal = () => {
    setModal({
      isOpen: false,
      content: null,
      title: ""
    });
  };

  const findByType = (type) => {
    const isExist = cmsList.find(
      (item) => _.get(item, "attributes.type") === type
    );
    if (isExist) return JSON.parse(_.get(isExist, "attributes.body"));
    return {};
  };

  useEffect(() => {
    getCms();
  }, []);

  useEffect(() => {
    if (!modal.isOpen)
      setModal({
        isOpen: false,
        content: null,
        title: "",
        hideHeader: false
      });
  }, [modal.isOpen])

  // temporaryy fix to remove application-info-change on '/'
  if (history.location.pathname === '/') {
    removeItem("application-info-change");
    removeItem("initial-application-info");
  }

  const TRACKING_ID = process.env.REACT_APP_GOOGLE_KEY || '';

  const trackPageView = location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  };

  useEffect(() => {
    if (TRACKING_ID !== '') {
      ReactGA.initialize(TRACKING_ID);
      trackPageView(history.location);
      history.listen(trackPageView);
    }
  }, []);

  return (
    <CmsContext.Provider
      value={{
        data: cmsList,
        findByType,
      }}
    >
      <HeaderComponent />
      <ModalContext.Provider value={setModal}>
        <Switch>
          <Route
            exact
            path={`${match.path}service-unavailable`}
            component={ServiceUnavailable}
          />
          {(typeof (isAuthenticated) === 'undefined' || isLoadingCms) && <Route path="/" render={() => <div>&nbsp;</div>} />}
          {isAuthenticated && <Route path="/pgi" component={PGIPage} />}
          {(typeof (isAuthenticated) !== 'undefined' && isAuthenticated) && (
            <Route
              path="/"
              render={() => <PrivateRoutes isAuthenticated={isAuthenticated} />}
            />
          )}
          {(typeof (isAuthenticated) !== 'undefined' && !isAuthenticated) && <Route path="/" component={PublicRoutes} />}
        </Switch>
        <Marker />
        <Modal key="modal-component" data={modal} cmsContext={cmsContext} onClose={onCloseModal}>
          {_.get(modal, "content")}
        </Modal>
        <Alert
          key="alert"
          stack={{ limit: 1 }}
          effect="slide"
          contentTemplate={AlertTemplate}
        />
      </ModalContext.Provider>
    </CmsContext.Provider>
  );
}

App.defaultProps = {
  isLoadingCms: true,
};

App.propTypes = {
  auth: PropTypes.instanceOf(Object).isRequired,
  modal: PropTypes.instanceOf(Object).isRequired,
  setModal: PropTypes.instanceOf(Function).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  getCms: PropTypes.instanceOf(Function).isRequired,
  cmsList: PropTypes.instanceOf(Array).isRequired,
  isLoadingCms: PropTypes.bool,
};

const mapStateToProps = ({ modal, api }) => ({
  modal,
  cmsList: _.get(api, "CMS.list") || [],
  isLoadingCms: _.get(api, "loading").indexOf("CMS") > -1,
});

const enhance = _.flowRight([
  withAuth,
  withRouter,
  connect(mapStateToProps, commonActions),
]);

export default enhance(App);

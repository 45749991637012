
import React from 'react';
import PropTypes from 'prop-types';

const CustomBubble = ({ message }) => {

  const user = localStorage.getItem('chat-account') ? JSON.parse(localStorage.getItem('chat-account')) : null

  return (
    <div className="bubble">
      {user && message.id !== user.id &&
        <small style={{ fontSize: 10 }} className={user && message.id === user.id ? 'name-right' : 'name-left'}>{message.senderName}</small>}
      <div className={user && message.id === user.id ? 'item-right' : 'item-left'}>
        <span className="message">
          {message.message}
        </span>
      </div>
    </div>
  )
}

CustomBubble.defaultProps = {
  message: {}
}

CustomBubble.propTypes = {
  message: PropTypes.instanceOf(Object)
}

export default CustomBubble;
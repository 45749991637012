import React, { useContext, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import FormInputMobile from "modules/common/components/FormInputMobile";
import Modal from "modules/common/components/Modal";
import SignUpContainerModal from "modules/auth/modals/SignUpModal";
import LoginContainerModal from "modules/auth/modals/LoginContainerModal";
import PropTypes from "prop-types";
import useFormValidator from 'useFormValidator';
import ForgotPasswordEnterPinModal from "./ForgotPasswordEnterPinModal";
import * as actions from "../actions";
import * as c from "../constants";
import CmsContext from "../../../CmsContext";


const form = {
  email: "",
  password: "",
  profile_type: "taxpayer",
};

function ForgotPasswordModal({
  forgotPassword,
  isLoading,
  history,
  serviceList
}) {
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");

  const [isEmail, setIsEmail] = useState(false);
  const [formData, setFormData] = useState(form);
  const setModal = useContext(ModalContext);

  const formValidator = useFormValidator(formData, {
    email: 'required'
  })

  useEffect(() => {
    if (isEmail) {
      formValidator.onSetObjectRules({
        email: 'required|email',
      })
    }
    if (!isEmail) {
      formValidator.onSetObjectRules({
        email: 'required|mobile_number_63',
      })
    }
  }, [isEmail])

  const handleOpenAuthModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <SignUpContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnSubmit = e => {
    // /* eslint-disable */
    e.preventDefault();

    if (isEmail === true) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          const args = {
            ...formData,
            recaptcha: token
          }
          forgotPassword(args, () => {
            history.push("/");
            setModal({ isOpen: false });
          });
        });
      });
    }

    if (isEmail === false) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          const args = {
            ...formData,
            recaptcha: token
          };
          forgotPassword(args, () => {
            e.preventDefault();
            setModal({
              isOpen: true,
              content: <ForgotPasswordEnterPinModal mobileNo={formData.email} />,
              title: null,
              modalSize: "modal-lg",
              hideHeader: true,
              isCentered: true
            });
          });
        });
      });
    }
  };

  const handleOnClose = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleGoBack = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <LoginContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnClickButton = () => {
    window.grecaptcha.enterprise.reset();
    setIsEmail(!isEmail);
    setFormData({ email: "" });

  };

  return (
    <Modal.Body className="p-0">
      <div className="forgot-container">
        <button onClick={handleGoBack} type="button" className="btn btn-link">
          <i className="fas fa-chevron-left back-login" />
        </button>
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">
                <img
                  src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                  alt=""
                  className="icon-logo"
                />
                <h2 className="form-title font-weight-bold mt-4 mb-0">Hi!</h2>
                <p className="form-sub-title">Forgot Password</p>
                <form onSubmit={handleOnSubmit}>
                  <div className="form-wrapper">
                    <div className="full-group border-bottom">
                      <label
                        htmlFor="mobile_number"
                        className="full-label mb-0 login-options"
                      >
                        <span> {isEmail ? "Email" : "Mobile Number"} {formValidator.renderMessage('email')}</span>
                        <span>
                          {" "}
                          <button
                            type="button"
                            onClick={handleOnClickButton}
                            className="btn btn-link"
                          >
                            {isEmail ? "Use Mobile No" : "Use Email"}
                          </button>
                        </span>
                      </label>
                      {isEmail ? (
                        <input
                          className="full-input"
                          type="email"
                          name="email"
                          value={_.get(formData, "email") || ""}
                          placeholder="ex. sample@email.com"
                          autoComplete="off"
                          autoFocus
                          required
                          onChange={handleOnChange}
                        />
                      ) : (
                        <FormInputMobile
                          disableDropdown={false}
                          autoComplete="off"
                          autoFocus
                          type="tel"
                          name="email"
                          onChange={setFormData}
                          value={formData.mobile_number || ""}
                        />
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 cms-bg-color-primary"
                    disabled={isLoading || !formValidator.response.is_passed}
                  >
                    {isLoading && <i className="fa fa-spin fa-spinner" />} Next
                  </button>
                  <Link
                    to="/"
                    onClick={handleOpenAuthModal}
                    className="sign-up mt-3 d-block"
                  >
                    Don’t have account yet? <strong>Sign up here.</strong>
                  </Link>
                </form>
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
}

ForgotPasswordModal.propTypes = {
  forgotPassword: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired
};

const removeENews = res => {
  const newArr = res.filter(d => {
    return d.attributes.name !== "E-News" && d.attributes.name !== "E-Wallet";
  });
  return newArr;
};

const mapStateToProps = ({ api }) => ({
  isLoading: _.get(api, "loading", []).indexOf(c.FORGOT_PASSWORD) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(ForgotPasswordModal);

import React, { useContext , useEffect, Suspense} from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import _ from "lodash";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Loader from './modules/common/components/Loader';
import * as actions from "./modules/landing/actions";


import CmsContext from './CmsContext';
import cmsColors from './cmsThemes/cmsColors'
import MetaTagDecorator from './MetaTagDecorator'

const MobileLogin = React.lazy(() => import('./modules/auth/mobile/MobileLogin'));
const ForgotPassword = React.lazy(() => import('./modules/auth/mobile/ForgotPassword'));
const SignUp = React.lazy(() => import('./modules/auth/mobile/SignUp'));
const LandingContainer = React.lazy(() => import('./modules/landing/containers/LandingContainer'));
const ContactUs = React.lazy(() => import('./modules/landing/containers/ContactUs'));
const About = React.lazy(() => import('./modules/landing/containers/About'));
const ActivateAccount = React.lazy(() => import('./modules/auth/containers/ActivateAccount'));
const Faq = React.lazy(() => import('./modules/faq/Faq'));
const HowTo = React.lazy(() => import('./modules/how-to/containers/HowTo'));
const Partial = React.lazy(() => import('./modules/landing/containers/Partial'));
const EhelpPartial = React.lazy(() => import('./modules/landing/containers/EhelpPartial'));
const EhelpRoute = React.lazy(() => import('./modules/transaction/containers/EhelpRoute'));
const PrivacyPolicy = React.lazy(() => import('./modules/privacy-policy/PrivacyPolicy'));
const TermsAndCondition = React.lazy(() => import('./modules/terms-and-condition/TermsAndCondition'));
const DownloadableForm = React.lazy(() => import('./modules/downloadable-form/DownloadableForm'));
const SingleContainer = React.lazy(() => import('./modules/landing/news/SingleContainer'));
const NewsLanding = React.lazy(() => import('./modules/landing/news/NewsLanding'));
const NewsContainer = React.lazy(() => import('./modules/landing/news/NewsContainer'));
const VideosContainer = React.lazy(() => import('./modules/landing/news/VideosContainer'));
const SSOComponent = React.lazy(() => import('./modules/sso/SSOComponent'));
const SuperAppSSOComponent = React.lazy(() => import('./modules/sso/SuperAppSSOComponent'));
const GMPResetPasswordContainerModal = React.lazy(() => import('./modules/auth/modals/GMPResetPasswordModal'));
const SuperAppCreatePasswordContainer = React.lazy(() => import('./modules/auth/containers/SuperAppCreatePasswordContainer'));
const serviceUnavailable = React.lazy(() => import('./modules/error-pages/serviceUnavailable'));

function PublicRoutes({ match, getServices }) {
  const cmsContext = useContext(CmsContext)
  const CMS_ICONS = cmsContext.findByType('ICONS');
  const CMS_BACKGROUNDS = cmsContext.findByType('BACKGROUNDS');
  const CMS_COLORS = cmsContext.findByType('COLORS');
  const CMS_VARIABLES = cmsContext.findByType('VARIABLES');
  const CMS_LOGOS = cmsContext.findByType('LOGOS');

  useEffect(() => {
    getServices({ order_by: "sequence", sort_by: "asc", status: 1 });
  }, [])

  return (
    <>
      <MetaTagDecorator
        title={_.get(CMS_VARIABLES, 'TITLE_SITE')}
        description="An electronic Real Property Taxing System that enables easy assessment and appraisal of real property taxes and allows online payments."
        imageUrl={_.get(CMS_LOGOS, 'SIMPLE_LOGO.cdnUrl')}
        siteUrl={_.get(CMS_VARIABLES, 'DOMAIN')}

        twitterCard={_.get(CMS_LOGOS, 'SIMPLE_LOGO.cdnUrl')}
        twitterImageAlt={_.get(CMS_LOGOS, 'SIMPLE_LOGO.cdnUrl')}
        siteName={_.get(CMS_VARIABLES, "TAG_LINE")}
      />
      <Helmet 
        style={[
          {
            "cssText" : `
              ${cmsColors(CMS_COLORS)}
              
              .section-left--egov {
                background-image: url("${_.get(CMS_ICONS, 'E_GOVERNEMENT_LARGE.cdnUrl')}");
              }

              .section-left--ebusiness {
                background-image: url("${_.get(CMS_ICONS, 'E_BILLS_LARGE.cdnUrl')}");
              }

              .section-left--ecommerce {
                background-image: url("${_.get(CMS_ICONS, 'E_COMMERCE_LARGE.cdnUrl')}");
              }

              .section-left--eservice {
                background-image: url("${_.get(CMS_ICONS, 'E_SERVICES_LARGE.cdnUrl')}");
              }

              .section-left--epayment {
                background-image: url("${_.get(CMS_ICONS, 'E_PAYMENT_LARGE.cdnUrl')}");
              }

              .login-container .info-section {
                background: url("${_.get(CMS_BACKGROUNDS, 'AUTH_BACKGROUND.cdnUrl')}") no-repeat 50%;
                background-size: cover;
              }

              .forgot-container .info-section {
                background: url("${_.get(CMS_BACKGROUNDS, 'AUTH_BACKGROUND.cdnUrl')}") no-repeat 50%;
                background-size: cover;
              }

              .about-banner {
                background: url("${_.get(CMS_BACKGROUNDS, 'ABOUT_BANNER.cdnUrl')}") no-repeat center center;
                background-size: cover;
              }

              .how-to-bg {
                background: url("${_.get(CMS_BACKGROUNDS, 'ABOUT_BANNER.cdnUrl')}") no-repeat center center;
                background-size: cover;
              }

              .app-store-bg {
                background: url("${_.get(CMS_BACKGROUNDS, 'APP_STORE_BACKGROUND.cdnUrl')}") no-repeat;
                height: 200px;
                background-size: 100%;
              }

              .btn-to-top {
                background: url("${_.get(CMS_ICONS, 'GO_UP.cdnUrl')}");
                background-repeat: no-repeat;
                background-size: cover;
              }
            `
          }
        ]}
      />
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path={`${match.path}service-unavailable`}
            component={serviceUnavailable}
          />
          <Route path={`${match.path}auth`} component={SSOComponent} />
          <Route path={`${match.path}superapp_sso_auth`} component={SuperAppSSOComponent} />
          <Route path={`${match.path}create-password`} component={GMPResetPasswordContainerModal} />
          <Route path={`${match.path}create-password-superapp`} component={SuperAppCreatePasswordContainer} />
          
          <Route path={`${match.path}articles/:id`} component={SingleContainer} />
          <Route path={`${match.path}articles`} component={NewsContainer} />
          <Route path={`${match.path}videos`} component={VideosContainer} />
          <Route path={`${match.path}news`} component={NewsLanding} />
          <Route
            path={`${match.path}about`}
            render={() => (
              <Partial title="About" description="">
                <About />
              </Partial>
            )}
          />
          <Route
            path={`${match.path}faq`}
            render={() => (
              <Partial title="FAQs" description="Frequently Asked Questions">
                <Faq />
              </Partial>
            )}
          />
          <Route
            path={`${match.path}downloadable-form`}
            render={() => (
              <Partial title="Downloadable Forms" description="">
                <DownloadableForm />
              </Partial>
            )}
          />
          <Route
            path={`${match.path}privacy-policy`}
            render={() => (
              <Partial title="Privacy Policy" description="">
                <PrivacyPolicy />
              </Partial>
            )}
          />
          <Route
            path={`${match.path}terms-and-condition`}
            render={() => (
              <Partial title="Terms and Condition" description="">
                <TermsAndCondition />
              </Partial>
            )}
          />
          <Route
            path={`${match.path}how-to`}
            render={props => (
              <Partial title="How Tos" description="How To Use">
                <HowTo match={props.match} />
              </Partial>
            )}
          />
          <Route path={`${match.path}contact-us`} component={ContactUs} />
          <Route
            path={`${match.path}account/activate/:token?/:email?`}
            component={ActivateAccount}
          />
          <Route
            path={`${match.path}e-help`}
            render={props => (
              <EhelpPartial title="E-Help">
                <EhelpRoute match={props.match} isPrivate={false}/>
              </EhelpPartial>
            )}
          />

          <Route path={`${match.path}mobile/login`} component={MobileLogin} />
          <Route path={`${match.path}mobile/sign-up`} component={SignUp} />
          <Route path={`${match.path}mobile/forgot-password`} component={ForgotPassword} />

          <Route path={`${match.path}`} component={LandingContainer} />

        </Switch>
      </Suspense>
    </>
  );
}

PublicRoutes.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  getServices: PropTypes.instanceOf(Function).isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(PublicRoutes);

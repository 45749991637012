/* global my */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import _ from "lodash";
import { get } from "session";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import EmptyState from "modules/common/components/EmptyState";
import Select from "react-select";
import { reactSelectCustomStyle, handleOpenPgiUrl, scrollToTop, payloadToUpperCase } from "helper";
import InputMask from "react-input-mask";
import useFormValidator from "useFormValidator";
import { ModalContext } from "App";
import GCashIcon from 'assets/img/gcash-white.png';
import CmsContext from "CmsContext";
import { VALID_IDS } from "constant";
// import PaymentGatewayIframeModalContent from 'modules/common/components/PaymentGatewayIframeModalContent';
import { getTransactionHistoryList } from "modules/transaction-history/actions";
import * as actions from "./actions";
import * as c from "./constants";
import * as lookUps from "../../../../constants";
import { generatePGI } from "../../../../actions";

const BTAXSearchCSV = ({
  searchBilling,
  searching,
  billingData,
  clearItem,
  create,
  processPGI,
  PGIgenerating,
  creating,
  module,
  processGetTransactionHistoryList,
  isGCashLogin
}) => {
  const history = useHistory()
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_MASKINGS = cmsContext.findByType("MASKINGS");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    bin: "",
  });

  const validateForm = useFormValidator(
    formData,
    {
      bin: "required",
    },
    {
      bin: {
        required: "Business Identification Number",
      },
    }
  );

  useEffect(() => {
    validateForm.onSetObjectRules({
      bin: `required|mask:${_.get(CMS_MASKINGS, 'BTAX_BIN_REGEX_CSV')}~${_.get(CMS_MASKINGS, 'BTAX_BIN_CSV')}`
    })
  }, [])

  const validateValidId = useFormValidator(formData, {
    valid_id_to_present: "required",
  });

  useEffect(() => {
    scrollToTop()
  }, [isSubmitted])

  useEffect(() => {
    validateForm.validate();
    validateValidId.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    clearItem({});
    return () => {
      setIsSubmitted(false);
      setFormData({});
      clearItem({});
    };
  }, []);

  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelect = (name) => (e) => {
    setFormData({
      ...formData,
      [name]: e.value,
    });
  };

  const onSearchBilling = (e) => {
    e.preventDefault();
    if (!validateForm.response.is_passed) {
      return false;
    }
    const finalPayload = {
      ...payloadToUpperCase({
        ...formData
      }),
      valid_id_to_present: _.get(formData, 'valid_id_to_present')
    }
    searchBilling(finalPayload, () => {
      setIsSubmitted(true);
    });

    return true;
  };

  const formatPeriod = (data) => {
    return data
      ? data
          .replace("-1", " - 1st quarter")
          .replace("-2", " - 2nd quarter")
          .replace("-3", " - 3rd quarter")
          .replace("-4", " - 4th quarter")
      : "---";
  };

  const onBack = (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    clearItem({});
  };

  const onSubmitAndPay = (e) => {
    e.preventDefault();

    const finalPayload = {
      ...payloadToUpperCase({
        ...formData,
        application_status_id: _.get(module, "application_status_id"),
      }),
      valid_id_to_present: _.get(formData, 'valid_id_to_present')
    }
    create(
      finalPayload,
      (response) => {
        processGetTransactionHistoryList();
        processPGI(
          _.get(response, "data.relationships.transaction_history.data.id"),
          (url, res) => {
            
            if(window.my && isGCashLogin){
              my.postMessage({
                url: _.get(res, 'included.gcashMiniProgramLogs.attributes.response.data.actionForm.redirectionUrl')
              });
            }else{
              handleOpenPgiUrl(url);
            }

            setModal({
              isOpen: false,
            });

            setIsSubmitted(false);
            setFormData({});
            clearItem({});
            history.push("/success-page");
          }
        );
      }
    );
  };

  const payLabel = isGCashLogin ? `Pay with GCASH` : `Pay Now`

  // render for PRT billing
  return (
    <>
      {!isSubmitted && _.isEmpty(billingData) && (
        <form onSubmit={onSearchBilling}>
          <div className='form-wrapper mb-3 pb-3  animated fadeIn'>
            <div className='full-group '>
              <div className='form-row'>
                <div className='form-input-group col-12'>
                  <label htmlFor='last_name' className='full-label mb-0'>
                    Business Identification Number (BIN){" "}
                    {validateForm.renderMessage("bin")}
                  </label>
                  <InputMask
                    placeholder={_.get(CMS_MASKINGS, 'BTAX_BIN_CSV')}
                    className='full-input text-uppercase'
                    maskChar=' '
                    mask={_.get(CMS_MASKINGS, 'BTAX_BIN_CSV')}
                    autoComplete='none'
                    id='bin'
                    name='bin'
                    onChange={onChangeInput}
                    value={_.get(formData, "bin") || ""}
                  />
                </div>
              </div>
            </div>
            <div className='full-group pt-3'>
              <button
                disabled={searching || !validateForm.response.is_passed}
                type='submit'
                className='btn btn-primary btn-lg w-100 cms-bg-color-primary cms-outline-primary'>
                {searching ? <i className='fa fa-spinner fa-spin' /> : `Submit`}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmitted && _.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <EmptyState message='No Record Found' />
          <div className='full-group'>
            <button
              onClick={onBack}
              type='button'
              className='btn btn-primary btn-lg w-100'>
              Back
            </button>
          </div>
        </>
      )}
      {isSubmitted && !_.isEmpty(_.omit(billingData, ["included"])) && (
        <>
          <div className='card  animated fadeIn'>
            <div className='row'>
              <div className='col-12 text-center cms-text-primary'>
                Business Identification Number (BIN)
              </div>
              <div className='col-12 text-center cms-text-primary'>
                <b>{_.get(billingData, "attributes.bin") || "---"}</b>
              </div>
            </div>
          </div>
          <br />
          <table className='table tabl-sm table-striped table-sm mb-0 border-bottom'>
            <tbody>
              <tr>
                <td>AMOUNT</td>
                <td>:</td>
                <td>
                  <b>{_.get(billingData, "attributes.amount_human")}</b>
                </td>
              </tr>
              <tr>
                <td style={{ width: 150 }}>ACCOUNT NAME</td>
                <td>:</td>
                <td>
                  <b>
                    {_.get(billingData, "attributes.account_name") || "---"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>FROM</td>
                <td>:</td>
                <td>
                  <b>
                    {formatPeriod(
                      _.get(billingData, "attributes.mode_of_payment_1")
                    )}
                  </b>
                </td>
              </tr>
              <tr>
                <td>TO</td>
                <td>:</td>
                <td>
                  <b>
                    {formatPeriod(
                      _.get(billingData, "attributes.mode_of_payment_2")
                    )}
                  </b>
                </td>
              </tr>
              <tr>
                <td>DATE PROCESSED</td>
                <td>:</td>
                <td>
                  <b>
                    {_.get(billingData, "attributes.date_processed")
                      ? moment(
                          `${_.get(billingData, "attributes.date_processed")}`
                        ).format("MMM DD, YYYY")
                      : "---"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>EXPIRATION DATE</td>
                <td>:</td>
                <td>
                  <b>
                    {_.get(billingData, "attributes.expiration_date")
                      ? moment(
                          `${_.get(billingData, "attributes.expiration_date")}`
                        ).format("MMM DD, YYYY")
                      : "---"}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='full-group '>
            <div className='form-row'>
              <div className='form-input-group col-12'>
                <label htmlFor='last_name' className='full-label mb-0'>
                  Valid ID to Present Upon Claiming{" "}
                  {validateValidId.renderMessage("valid_id_to_present")}
                </label>
                <Select
                  styles={reactSelectCustomStyle}
                  placeholder='Select'
                  value={
                    VALID_IDS.find(
                      (item) =>
                        _.get(formData, "valid_id_to_present") ===
                        _.get(item, "value")
                    ) || ""
                  }
                  onChange={onChangeSelect("valid_id_to_present")}
                  options={VALID_IDS}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
          <div className='alert alert-primary text-center mt-3' role='alert'>
            Note: An additional convenience fee will be charged to use this
            e-payment service, and once a reference number is generated, please
            pay with in the day.
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-6 mb-1 mb-md-0 pr-md-1'>
              <button
                disabled={PGIgenerating || creating}
                onClick={onBack}
                type='button'
                className='btn btn-primary cms-bg-color-primary btn-lg w-100'>
                Back
              </button>
            </div>
            <div className='col-12 col-md-6'>
              <button
                onClick={onSubmitAndPay}
                disabled={
                  PGIgenerating ||
                  creating ||
                  !validateValidId.response.is_passed
                }
                type='submit'
                className={`btn btn-success btn-lg w-100 d-flex align-items-center ${isGCashLogin ? 'gcash-icon' : ''}`}>
                {isGCashLogin && <img src={GCashIcon} alt="GCash" width="22" height="22" />}
                {PGIgenerating || creating ? (
                  <i className='fa fa-spinner fa-spin' />
                ) : (
                  <p className="flex-grow-1 d-flex align-items-center justify-content-center m-0">{payLabel}</p>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

BTAXSearchCSV.propTypes = {
  processGetTransactionHistoryList: PropTypes.instanceOf(Function).isRequired,
  searchBilling: PropTypes.instanceOf(Function).isRequired,
  create: PropTypes.instanceOf(Function).isRequired,
  searching: PropTypes.bool.isRequired,
  billingData: PropTypes.instanceOf(Object).isRequired,
  clearItem: PropTypes.instanceOf(Function).isRequired,
  processPGI: PropTypes.instanceOf(Function).isRequired,
  PGIgenerating: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  isGCashLogin: PropTypes.bool.isRequired
};

const mapStateToProps = ({ api }) => ({
  searching: api.loading.indexOf(c.SEARCH_BILLING) > -1,
  billingData: _.get(api, `${c.SEARCH_BILLING}.item`) || {},
  PGIgenerating: api.loading.indexOf(lookUps.GENERATE_PGI) > -1,
  creating: api.loading.indexOf(c.CREATE) > -1,
  isGCashLogin: !!_.get(
    JSON.parse(get("profile")),
    "attributes.is_gcash_login"
  )
});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
    processPGI: generatePGI,
    processGetTransactionHistoryList: getTransactionHistoryList,
  }),
]);

export default enhance(BTAXSearchCSV);
